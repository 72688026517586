.footerContainer {
    width: 100%;
    height: auto 0px;
    background-color: #263489;
    padding-top: 60px;
    /* padding-bottom: 30px; */
    color: white;
    text-align: center;

}

.footerContainer>div>div>div {
    justify-content: space-around;
}

.icon {
    max-width: 29px;
}

/* .links {
    max-width: 100%;
    width: 100%;
} */

.links img {
    float: left;
}

.margin {
    padding: 4px;
}

.links>div {
    text-align: left;
    /* margin: 6px; */

    font-weight: bold;
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 16px;
}


.links .TitleFooter {
    padding-bottom: 24px;

}

.links div span {
    cursor: pointer;
    font-family: "Book", Arial, serif !important;

}

.TitleFooter {
    font-family: 'Open Sans';
    font-size: 20px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
}

.footerContainer span {
    font-family: 'Open Sans';
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFFCC;
}


.footerContainer>div>div {
    /* min-width: 1186px; */
    max-width: 1370px;
    /* margin-bottom: 16px; */
}

.espa {
    max-width: 56%;
}

.footerAtag span>a {
    color: #FFFFFFCC !important;
}

.footerAtag a:hover {
    text-decoration: underline;
}

.dotsoft {
    color: white;
}

.dotsoft:hover {
    color: white;
}

.copyrightsContainer {
    padding: 16px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    margin-top: 30px;

    font-family: 'Open Sans';
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
}

.signUp {
    background: #7BB6E1;
    border-radius: 2px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px !important;
    line-height: 27px;

    color: #FFFFFF;
    width: 117px;
    height: 57px;
}

.signUp span {
    font-size: 20px;
}

.formItem>div>div>div>div {
    display: flex;
}

.formItem>div>div>div>div input {
    border-radius: 0px !important;
    min-width: 100%;
}

.signUp:hover {
    background-color: #7bb6e1 !important;
}

.innerLeftCol {
    max-width: 337px;
    float: left;
}

.innerMiddleCol {
    max-width: 316px;
    margin: 0 auto;
}

.innerRightCol {
    max-width: 353px;
    float: right;
}

.placeholder {
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    padding: 17px 24px;
    height: 57px;
}

@media only screen and (min-width: 576px) and (max-width: 1430px) {
    .footerContainer>div>div {
        /* flex: 0 0 80% !important; */
        max-width: 93%;
    }
}

@media only screen and (min-width: 680px) {
    .footerContainer>div>div {
        min-width: 0 !important;

    }
}

@media only screen and (max-width: 1200px) {
    .footerContainer>div>div {
        /* flex: 0 0 80% !important; */
        max-width: 93%;
    }

}

@media only screen and (max-width: 991px) {
    .innerRightCol {
        float: left !important;
        margin: 2rem 0 0 0 !important;
    }

    .innerMiddleCol {
        float: left !important;
        margin: 0 !important;
    }
}

@media only screen and (max-width: 680px) {
    .links {
        max-width: 100%;
    }

    .links div {
        /* margin: 15px; */
        padding-bottom: 2px;
        /* padding-left: 16px;
        padding-right: 16px; */
    }

    .footerContainer>div>div {
        min-width: 0 !important;
    }

    .espa {
        max-width: 78%;
    }

    .formItem>div>div>div>div {
        display: block;
    }

    .signUp {
        width: 100%;
    }

    .placeholder {
        text-align: center !important;
    }

    .copyrightsContainer {
        font-size: 12px;
    }
    .footerContainer span {
        font-size: 14px;
    }

    .TitleFooter {
        font-size: 18px;
    }
}