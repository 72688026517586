.list {
  list-style-type: none;
  height: 100%;
  display: flex;
  justify-content: space-around;
  position: relative;
  align-items: flex-end;
}

.list a {
  color: #275674;
  height: 100% !important;
  align-items: flex-end;
  display: flex;
  justify-content: center;
  font-family: "Open Sans", sans-serif !important;
}

.active {
  font-weight: 500 !important;
}

.items {
  margin: 0;
}

.itemsAdmin {
  margin: 0;
}

.userInitials {
  padding: .25rem;
  margin: 0 1rem;
  border: 1px solid #FFF;
  border-radius: 2rem;
}

.items a {
  cursor: pointer;
  height: 93px !important;
  height: fit-content;
  padding-right: 8px;
  padding-left: 8px;
  text-align: center;
  text-decoration: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;

  color: #1a2560;
  text-decoration: none;
  display: flex;
  /* align-items: center; */
  align-items: baseline;
  border-bottom: 4px solid transparent;
}

.itemsAdmin a {
  cursor: pointer;
  height: 93px !important;
  padding-right: 8px;
  padding-left: 8px;
  text-align: center;
  text-decoration: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;

  color: #ffffff;
  text-decoration: none;
  display: flex;
  align-items: center;
  border-bottom: 4px solid transparent;
}

.items a:hover {
  color: #78b7e3;
}

.sima {
  font-size: 22px;
  font-weight: 600;
  color: #1b7093;
}

.sima2 {
  font-size: 29px;
  font-weight: 600;
  color: #1b7093;
}

.useful {
  cursor: pointer;
  height: 24px;
  text-align: center;
  text-decoration: none;
  color: #1b7093;
  font-weight: 600;
  text-decoration: none;
}

.sima2 .useful {
  font-size: 22px;
}

.btn {
  display: none;
  position: relative;
  right: 25px;
  bottom: 80%;
  padding: 0;
  color: #275674;
  font-size: 17px;
  border: none;
}

.searchButton {
  display: none;
}

Menu {
  background-color: rgb(255, 0, 0);
}

.closeIcon {
  color: #1a2560;
  font-size: 1.1rem;
}

.navbar {
  background-color: yellow;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 99999999999999;
  position: relative;
  margin-top: 40px;
}

.menuBars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}

.navMenu {
  background-color: #fff;
  width: 300px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -300px;
  z-index: 999999999;
  transition: 850ms;
}

.subMenu {
  background-color: #2a576e;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -300px;
  z-index: 999999999;
  transition: 850ms;
}

.navMenuActive {
  right: 0;
  position: absolute;
  z-index: 9999999999;
  background-color: #fff;
  width: 300px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 999999999;
  transition: 850ms;
  border-left: 1px solid white;
  overflow-y: scroll;
  box-shadow: 0px 0px 0px 3px #00000029;
}

.subMenuActive {
  left: 0;
  position: absolute;
  z-index: 9999999999;
  background-color: #2a576e;
  width: 300px;
  height: 10vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 999999999;
  transition: 850ms;
  border-left: 1px solid white;
  overflow-y: scroll;
}

.navText {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 46px 8px 16px;
  list-style-type: none;
  background-color: #fff;
}

.navText a:first-child {
  text-decoration: none;
  color: #1a2560;
  font-size: 18px;
  width: 215px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-bottom: 1px solid #26348929;

  color: #1a2560;
  font-family: "Open Sans", sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.navText:last-child a {
  border-bottom: 0px !important;
  border-color: transparent !important;
}

.navText > li > a:not(:first-child) {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 46px 8px 16px;
  list-style-type: none;
  height: 30px;
  background-color: #fff;
  position: relative;
  color: #537b8f;
}

.navMenuItems {
  width: 100%;
}

.navbarToggle {
  background-color: #fff;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* span{
    margin-left: 16px;
  } */

.socialSidebar {
  max-width: 140px;
  padding: 10px 25px;
  bottom: 0;
  position: relative;
  /* margin-top: 15vw; */
}

.socialSidebar img {
  max-width: 40px;
}

.selectLang {
  margin-left: 10px;
  height: 42px;
  width: 50px;
  float: none;
  z-index: 999999999999999999999999999 !important;
}

.selectLang div {
  z-index: 99999999999999999;
}

.selectLang img {
  margin-top: 5px;
  z-index: 99999999999999999;
}

.selectLang span {
  padding-inline-end: 0px !important;
}

.loggedContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-self: flex-start;
}

.login {
  float: right;
  background: #263489;
  border-radius: 4px !important;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */
  width: 148px;
  height: 56px !important;

  color: #ffffff;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

@media screen and (max-width: 1180px) {
  .btn {
    position: absolute;
    right: -50%;
    bottom: 0;
    padding: 5px;
    color: #263489;
    font-size: 17px;
    border: none;
    z-index: 999999999;
    background-color: transparent;
  }

  .list {
    height: 100%;
    right: 10px;
    z-index: 999;
  }

  /* .items>a {
    width: 100%;
    margin-right: 0px;
    cursor: pointer;
    height: 10px;
    padding-right: 10px;
    padding-left: 10px;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    color: black;
    text-decoration: none;
  } */

  /* .list a {
    width: 100% !important;
  } */

  .btn {
    display: flex;
    font-size: 30px;
    align-items: center;
    height: 100%;
  }
}

@media screen and (max-width: 575px) {
  .list {
    flex-direction: column;
    height: auto;
    top: 91px;
    right: 4px;
    position: absolute;
    background: #e4eff0;
    z-index: 999;
    width: 240px;
  }

  .list a {
    color: #275674;
  }

  .sima {
    display: none;
  }

  .sima2 {
    padding-top: 12px;
    color: #275674;
  }

  .searchButton {
    display: block;
    padding-top: 8px;
    padding-bottom: 10px;
  }

  .btn {
    /* position: relative; */
    float: right;
    right: -12px;
    padding: 5px;
    color: #263489;
    font-size: 23px;
    border: none;
    z-index: 9;
    /* background-color: transparent; */
  }
}

@media screen and (min-width: 1180px) and (max-width: 1379px) {
  /* .items {
    padding-top: 12px;
    padding-bottom: 12px;
    margin-right: 7px;
  } */
}

/* @media screen and (min-width: 1380px){
      .items {
          padding-top: 35px;
      }
    } */
