body {
  margin: 0;
  font-family: 'Open Sans', sans-serif,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html{
  font-family: 'Open Sans', sans-serif;
}
code {
  font-family: 'Open Sans', sans-serif;
}

body {
  background-color: white;
  margin: auto;
  padding: 0px;
  overflow-x: hidden;
  background-repeat: repeat;
  font-family: 'Open Sans', sans-serif;
  /* display: grid; */
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  background-color: #2a576e;
}
.root-container {
  zoom: 1;
  transform-origin: top center;
}

img {
  max-width: 100%;
  height: auto;
}

.sec__one {
  position: relative;
  width: 100%;
  display: flex;
  padding: 60px 0;
}

.sec__one h1 {
  text-align: center;
  display: block;
  margin: 0 auto;
  color: grey;
  font-family: 'Open Sans', sans-serif;
}

.navBar .logo img {
  float: left;
  max-height: 120px;
}

.is-active {
  color: #fff !important;
}

.menu-item .active {
  color: red !important;
}

.navBar .col-md-3 {
  z-index: 7;
}

ul.main-menu {
  display: inline-flex;
  list-style: none;
  margin: 0px auto 0;
  padding: 0;
  float: right;
  z-index: 7;
}

ul.main-menu>li.menu-item:first-child {
  padding-left: 0;
  margin-left: 0;
}

.menu-item {
  line-height: 23px;
}

/* li {
  list-style-type: none;
} */

.menu-item a {
  font-size: 20px;
  font-weight: 600;
  line-height: 40px;
  position: relative;
  display: block;
  color: #fff !important;
  margin: 27px 17px;
  text-decoration: none;
  cursor: pointer;
}

.header__middle__logo {
  width: 20%;
  display: inline-block;
  /* float: left; */
}

.header__middle__menus {
  width: 70%;
  display: inline-block;
  /* float: left; */
}

.menu-item.menu__qqqitem a {
  margin: 27px 10px;
}

.header__middle__search {
  width: 7%;
  display: inline-block;
  /* float: right; */
  padding: 36px 3px 27px 3px;
  border-left: 1px solid lavender;
}

.header__middle {
  display: flex;
  width: 100%;
  float: left;
  border-bottom: 1px dashed lavender;
  position: relative;
}

.header__middle__logo>a>h1 {
  color: #07a8ff;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 33px;
}

/* .active{
  color: #ff1800!important;
} */
.header__middle__menus span.text {
  position: absolute;
  right: 177px;
  bottom: 10px;
  font-size: 14px;
  color: #ff1800;
}

.sub__menus__arrows {
  position: relative;
}

.sub__menus__arrows svg,
.sub__menus__arrows__full svg {
  padding-top: 0px;
  position: absolute;
  top: 40%;
  font-size: 13px;
}

.sub__menus {
  position: absolute;
  display: none;
  background: #497a93;
  border: 1px solid white;
  border-radius: 5px;
  width: 180px;
  margin: 0 auto;
  /* left: -17px; */
  /* padding: 2px 0 0 0; */
  top: 82px;

}

.arrow-menu {
  font-size: 10px !important;
  top: 15px !important;
}

.socialSidebar {
  max-width: 140px;
  padding: 25px;
  bottom: 0;
  position: relative;
  /* margin-top: 15vw; */
  float: right;
}

.socialSidebar img {
  max-width: 40px;
}

.select-lang {
  height: 42px;
  top: 28px;
  width: 70px;
  float: right;
  z-index: 999999999999999999999999999 !important;
}

.select-lang div {
  z-index: 99999999999999999;
}

.select-lang img {
  margin-top: 5px;
  z-index: 99999999999999999;
}


@media (max-width:992px) {
  .sub__menus__Active .active {
    color: pink !important;
    background-color: #abbdc7;
    z-index: 0;
  }

  .select-lang {
    position: absolute;
  }

  .sub__menus {
    position: unset;
    display: none;
    background: #497a93;
    border-top: 1px solid #000;
    border-radius: 0;
    width: 99vw;
    margin: 0 auto;
    /* left: -17px; */
    /* padding: 2px 0 0 0; */

  }

  .menu-item {
    line-height: 23px;
    border-bottom: 1px solid black;
    width: 100%;
  }

  .sub__menus__Active a {
    margin: 0;
  }


}


@media (min-width:992px) {
  .sub__menus__arrows:hover .sub__menus {
    display: block;
  }

  .sub__menus__arrows__full:hover .sub__menus {
    display: block;
    z-index: 1000000;
  }

  .main-nav ul.main-menu {
    display: none;
    height: 82px;

    right: 0;
    position: relative;
    /* z-index: 9999999999; */
    /* background-color: #2a576e; */
    width: 100%;
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    position: fixed;
    /* top: 0; */
    z-index: 999999999;
    transition: 850ms;
    border-left: 1px solid white;
  }

  .sub__menus__arrows {
    position: relative;
    height: 100%;
  }
}

/* .menu-item .sub__menus a {
  padding: 7px 0 0px 0;
  margin: 7px 27px;
} */

.menu-item:hover>a {
  color: green !important;
}

.menu-item .sub__menus li:hover a {
  color: #a6aaac !important;
}

.header__middle__logo img {
  max-width: 150px;
  margin: 10px 0 0 0;
}


@media(max-width:992px) {

  .header__middle__logo {
    width: 67%;
  }

  .header__middle__menus {
    width: 25%;
  }

  .header__middle__logo img {
    max-width: 90px;
  }

  .main-nav .menubar__button:hover {
    background-color: #545050
  }

  .main-nav .menu-item:hover>a {
    color: #fff !important
  }

  .main-nav .menubar__button {
    display: block !important;
    float: right;
    background-color: #080808;
    color: #fff;
    padding: 6px 7px;
    border-radius: 5px;
    margin: 27px auto 0;
    cursor: pointer;
    position: relative;
    z-index: 10037;
  }

  .main-nav .menubar__button svg {
    font-size: 27px;
  }

  .main-nav ul.menuq1 {
    /* display: block !important; */
    left: 0;
    z-index: 10007;
    border-bottom: 3px solid #000;
    background-color: #2a576e;
    right: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -201vw;
    transition: 850ms;
    /* padding-top: 25%; */
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
  }

  /* .main-nav ul.menuq1 li{
    height: 10vh;
  } */

  .main-nav ul.menuq2 {
    /* display: block !important; */
    /* position: absolute; */

    z-index: 1;
    background-color: #2a576e;
    right: 100%;
    width: 100%;
    height: 100vh;
    transition: 850ms;
    display: flex;
    overflow-y: scroll;
  }

  .main-nav .menu-item a {
    margin: 10px 0px;
    text-align: center;
  }

  /* .sub__menus__Active>li>a {
    margin: 0px 0px !important;
  } */

  .main-nav ul.main-menu {
    display: none;
    height: 100vh;
  }

  .main-nav .sub__menus__arrows:hover .sub__menus {
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  .sub__menus__Inactive {
    display: block;
    margin: 0 auto;
    left: -64px !important;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    transition: 350ms;
    color: transparent;
    border: none;
    background-color: transparent;
    height: 0;
  }

  .sub__menus__Inactive a {
    color: transparent !important;

  }

  .sub__menus__Active {

    display: block;
    margin: 0 auto;
    left: -64px !important;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    transition: 350ms;
    visibility: visible;
    z-index: 99999;
  }

  .sub__menus__Active2 {
    display: block !important;
    transition: 850ms;
  }

  .menus__categorysss {
    display: none;
  }

  .menus__categorysss2 {
    display: none;
  }

  .menu-item .sub__menus__full a {
    text-align: left;
  }

}