.innerSpan {
    margin-bottom: 16px;
    
}

.footerAtag span>a {
    color: #FFFFFFCC !important;
}

.footerAtag a:hover {
    text-decoration: underline;
}