.header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  /* min-width: 100%;
    min-height: 90px;
    max-height: 109px; */
  padding: 0px 0px;
  background-color: #ffffff;
  z-index: 3;
}

.headerAdmin {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  /* min-width: 100%;
    min-height: 90px;
    max-height: 109px; */
  padding: 0px 0px;
  background-color: #7bb6e1;
  z-index: 3;
}

.flexContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 3px 0px #00000029;
  z-index: 999;
}

.div {
  height: 60px;
  position: relative;
  margin-top: 10px;
  color: #275674;
}

.app_logo {
  /* flex: 0 1 auto; */
  /* max-height: 34px; */
  width: 265px;
  cursor: pointer;
  z-index: 1;
  /* aspect-ratio: 313 / 29; */
}

.app_logo img {
  display: flex;
  /* max-width: 93px; */
  /* max-width: 83px; */
  justify-content: center;
  /* padding-left: 30px; */
}

.container {
  max-width: 100%;
  margin: 0 auto;
  display: grid;
  gap: 0.5rem;
  align-items: center;
  justify-items: center;
}

.containerBottom {
  width: 100%;
  margin: 0px 0% 0px 0%;
  display: grid;
  gap: 0;
  align-items: center;
  justify-items: flex-end;
  grid-template-columns: 100% 00%;
  width: 1320px;
  margin: 0 auto;
}

.topBarContainer {
  height: 56px;
  background-color: #263489;
}

.topBarContainer > div {
  max-width: 1320px !important;
  margin: 0 auto !important;
  height: 100%;
}

.Rectangle1 {
  width: 100%;
  height: 96px;
  flex-grow: 0;
  /* padding: 0 161px 17px 39px; */
  background: #ffffff;
  /* display: flex; */
  color: #fff;
}

.Rectangle1Admin {
  width: 100%;
  height: 96px;
  flex-grow: 0;
  /* padding: 0 161px 17px 39px; */
  background: #7bb6e1;
  /* display: flex; */
  color: #fff;
}

/* .Rectangle1>div {
    max-height: 75%;
} */

.Rectangle1 > div {
  margin: 0 auto;
  height: 96px;
}

.Rectangle1 > div > div > div {
  margin: 0 auto;
  height: 96px;
  justify-content: sap;
}

.headerRight {
  display: flex;
  justify-content: space-between;
}

.TitleHeader {
  color: #fff !important;
  font-size: 36px;
  line-height: 35px;
  padding: 21px 15px 0 15px;
  font-family: "Open Sans", sans-serif !important;
}

.TextHeader {
  color: #fff !important;
  font-size: 14px;
  padding: 0 15px;
  color: #fff;
  margin-top: 10px;
  max-width: 320px;
  float: right;
  font-weight: 500 !important;
  font-family: "Open Sans", sans-serif !important;
}

.Rectangle1 > img {
  max-width: 100%;
}

.icon {
  top: 25%;
  position: relative;
  margin: 0 4rem 0 -4rem;
  max-width: 40px;
}

.topBarBox {
  display: flex;
  float: right;
  align-items: center !important;
  color: white;
  height: 100%;
}

.etpa {
  display: flex;
  align-items: center;
}

.loginCol {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.loginCol button {
  justify-content: flex-end;
}

.notLogged {
  float: right;
  background: #263489;
  border-radius: 4px !important;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px !important;
  line-height: 25px;
  width: 148px;
  height: 56px !important;

  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-evenly !important;
}
.notLoggedAdmin {
  float: right;
  background: #263489;
  border-radius: 4px !important;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px !important;
  line-height: 25px;
  width: 148px;
  height: 56px !important;

  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-evenly !important;
  box-shadow: none !important;
}

.login,
.loginAdmin {
  float: right;
  background: white;
  border-radius: 4px !important;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px !important;
  line-height: 25px;
  width: 148px;
  height: 56px !important;

  color: #003168;
  display: flex;
  align-items: center;
  /* justify-content: space-evenly; */
  box-shadow: none;
}

.login:hover {
  background-color: white !important;
}
.login:hover span {
  color: #7bb6e1;
}

.notLogged:hover {
  background-color: #78b7e3 !important;
}

.loginAdmin:hover {
  background-color: #606aa3 !important;
}


/* .login svg {
    transform: rotate(270deg);
} */

.socialSidebar {
  max-width: 140px;
  padding: 10px 25px;
  bottom: 0;
  position: relative;
  height: 76px;
  display: flex;
  align-items: center;
  border-radius: 4px !important;
  /* margin-top: 15vw; */
}

.selectLang > div {
  /* background-color: blue !important;
    color: white !important; */
  border: 2px solid #003399 !important;
  border-radius: 4px !important;
  font-weight: bold !important;
}

.selectLang span {
  font-weight: bold !important;
  font-family: "Open Sans";
  display: flex;
  align-items: center;
}

.socialSidebar span {
  color: #003399;
}

.socialSidebar img {
  max-width: 40px;
}

@media only screen and (min-width: 576px) and (max-width: 1430px) {
  .topBar {
    padding: 0 5%;
  }

  .containerBottom {
    max-width: 90%;
  }
}

@media only screen and (max-width: 1180px) {
  .socialSidebar {
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 1180px) {
  .container {
    grid-template-columns: auto auto auto;
  }

  .containerBottom {
    display: block;
  }

  .login,
  .loginAdmin,
  .notLogged {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .topBarContainer,
  .icon,
  .loginCol {
    display: none;
  }

  .container {
    grid-template-columns: 20% 60% 20%;
    font-size: 15px;
    padding: 4px 0px 10px 0px;
  }

  .app_logo {
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .app_logo img {
    /* width: auto; */
    max-width: 94px;
    max-height: 45px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  .etpa img {
    width: 162px !important;
  }

  .div {
    height: 120px;
  }

  .containerBottom {
    width: 100%;
    margin: 0px 0% 0px 0%;
    display: block;
    gap: 0rem;
    align-items: center;
    justify-items: center;
  }

  .Rectangle1 {
    width: 100%;
    height: 100%;
    flex-grow: 0;
    margin: 0 !important;
    /* padding: 0 161px 17px 39px; */
    background-color: #fff;
    display: block;
    color: #fff;
  }

  /* .Rectangle1>div {
        min-width: 0;
        max-height: 75%;
    } */

  .TitleHeader {
    color: #fff !important;
    font-size: 1.1rem;
    line-height: 1;
    padding: 21px 0 0 15px;
    margin-right: 1rem;
  }

  .TextHeader {
    display: none;
  }

  .app_logo img {
    padding-left: 0px;
    float: left;
    justify-content: flex-start;
  }

  .Rectangle1 > div {
    margin: 0 auto;
    height: 77px;
  }

  .Rectangle1 > div > div > div {
    margin: 0 auto;
    height: 77px;
  }
}
