.loading {
    font-size: 24px;
    font-weight: 600;
    height: 220px;
    margin: 20px 0;
    display: flex;
    justify-content: center;
}

.loading div {
    color: #323f5f;
    font-size: 1.3rem;
}

.spin {
    color: #7BB6E1 !important;
}

@media screen and (max-width: 680px) {

    .loading {
        font-size: 18px;
    }


}