.btn {
  float: right;
  background: #7DB7E3;
  border-radius: 3px;
}

.btnlink {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  color: rgba(123, 182, 225, 0.6);
  ;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
  float: right;
  display: block;
  width: 100%;
  text-align: right;
  cursor: pointer;
}

.btnlink:hover {
  color: #1A2560;
}

.fcontainer {
  position: relative;
  float: none;
  display: block;
  margin-top: 60px;
  padding: 0 50px 50px 50px;
}

.clabel {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  color: #1A2560;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
  /* float: left; */
  display: flex;
  width: 100%;
}

.clabel2 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  color: #1A2560;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
  /* float: left; */
  display: flex;
  width: 100%;
  text-align: end;
  justify-content: flex-end;
}

.clabel3 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  color: #1A2560;
  margin-top: 60px;
  margin-bottom: 24px;
  position: relative;
  /* float: left; */
  display: flex;
  width: 100%;
  text-align: end;
  justify-content: flex-start;
}

.cres {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  color: rgba(0, 0, 0, 0.6);
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
  float: left;
  display: flex;
  width: 100%;
}

.cresFileTitle {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 1.25rem;
  color: black;
}

.cresFile {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 1.25rem;
  color: rgba(0, 0, 0, 0.6);
  min-width: 35%;
}



.cres2 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  color: rgba(0, 0, 0, 0.6);
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  text-align: right;
}

.formContainer {
  margin: auto;
  width: 30%;
  padding-bottom: 20px;
  margin-top: 50px;
  margin-bottom: 50px;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
}

.formContainer label {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem !important;
  color: #1A2560;
  position: relative;
  float: left;
  display: block;
}

.formContainer input {
  position: relative;
  float: left;
  display: block;
  border: 2px solid rgba(123, 182, 225, 0.6);
  border-radius: 3px;
}

.formupper {
  background: #7BB6E1;
  border-bottom: 6px solid #263489;
  padding: 20px;
  position: relative;
  float: none;
  display: block;
  font-size: 1.25rem;
}

.formupper img {
  position: relative;
}

.formupper span {
  color: white;
  font-weight: bold;
  text-align: right;
  position: absolute;
  padding-right: 20px;
  padding-left: 20px;
  font-family: 'Open Sans', sans-serif;
}

.formContainer1 {
  padding: 20px;
  position: relative;
  float: none;
  display: block;
}

.container {
  width: 1320px;
  /* max-width: 90%; */
  margin-top: 40px !important;
  margin-bottom: 40px !important;
  /* padding: 50px; */
  height: auto;
  background: rgba(38, 52, 137, 0.02);
  border: 1px solid rgba(38, 52, 137, 0.16);
}

.containerPrograms {
  padding: 80px 50px;
  max-width: 95%;
  height: auto;
  background: rgba(38, 52, 137, 0.02);
}

.container h2 {
  color: #1A2560;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  font-size: 32px;
}

.container>div>div>div {
  max-width: 100%;
}

.title {
  color: var(--blue);
  font-size: 44px;
  text-align: left;
  font-weight: 700;
  padding: 40px 0px 20px 0px;
  line-height: 1.2;
}

.subTitle {
  color: var(--blue);
  font-size: 22px;
  text-align: left;
  line-height: 1.3;
  padding: 20px 20px 10px 180px;
  white-space: break-spaces;
}

.subTitleText {
  color: var(--blue);
  font-size: 22px;
  text-align: right;
  position: absolute;
  bottom: 70px;
  right: 50px;
}

.subTitleImage {
  text-align: right;
  position: absolute;
  bottom: 0;
}

.hr {
  display: inline-block;
  width: 150px;
  margin-left: 0;
  height: 5px;
  border-width: 0;
  background-color: var(--blue);
  margin: 0px 30px 12px 0px;
}

.image {
  width: 110%;
  height: auto;
}


p {
  white-space: break-spaces;
}

.info {
  max-width: 1186px;
  background-color: rgba(42, 87, 110, 0.2);
  margin: 0 auto;
}

.TitlePosts {
  font-size: 16px;
  color: #2a576e;
  text-align: center !important;
  margin: 0 auto !important;
  background-color: #d4dce0;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .TitlePosts:nth-child(1) {
  border-right: 1px dashed #2a576e;
}
.TitlePosts:nth-child(2) {
  border-right: none !important;
} */

.titleSection {
  font-size: 16px;
  color: #fff;
  text-align: center !important;
  margin: 0 auto;
  padding: 13px 513px 14px 550px;
  background-color: #2a576e;
}

.textBold {
  padding: 78px 78px 0 78px;
  font-size: 29px;
  font-weight: bold;
  color: #2a576e;
}

.text {
  padding: 44px 78px 44px 79px;
  font-size: 16px;
}

.Post {
  padding: 47px 47px;
  max-width: 489px;
  margin: 0 auto;
  /* min-height: 473px; */
}

.ImagePost {
  /* max-height: 324px; */
  max-height: 269px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  max-width: 100%;
}

.Date {
  font-size: 16px;
  color: #696e71;
  margin: 10px auto;
}

.TitlePost {
  font-size: 20px;
  font-weight: 500;
  color: #2a576e;
}

.ExcerptPost {
  font-size: 16px;
  color: #0e0f0f;
  margin-top: 10px;
  margin-right: 6px;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.PostBtn {
  height: 46px;
  flex-grow: 0;
  border-radius: 14px !important;
  background-color: #2a576e;
  margin-top: 20px;
  letter-spacing: 1.82px;
  color: #fff;
  font-size: 11px !important;
  padding: 0 19px !important;
}

.PostBtn span {
  font-family: "Book", Arial, serif !important;
  font-size: 11px;
  letter-spacing: 1.82px;
  color: #FFF;
}

.PostBtn:hover,
.PostBtn:focus {
  /* box-shadow: 0 0.1em 0.5em -0.4em white; */
  /* transform: translateY(-0.1em); */
  border-bottom: 1px solid transparent;
  /* transform: translateX(0.2em); */
  /* border-color: #fff !important; */

  -webkit-transform: scale(0.95) !important;
  -moz-transform: scale(0.95) !important;
  -ms-transform: scale(0.95) !important;
  -o-transform: scale(0.95) !important;
  transform: scale(0.95) !important;
  transition: .4s;
}

.pagination {
  margin-top: 55px;
  display: flex !important;
  justify-content: center !important;
}

.pagination li,
.ant-pagination-item-active:active {
  min-height: 47px;
  min-width: 47px !important;
  height: 36px;
  /* background: transparent !important; */
  display: grid !important;
  align-items: center;
  border-color: #bcbcbc !important;
}

.pagination li:first-child,
.pagination li:last-child {
  display: none !important;
}

.pagination a {
  color: #bcbcbc !important;
}

.pagination .ant-pagination-item-active {
  background-color: #2a576e !important;
}

li>div {
  max-width: 100%;
}

.dashed {
  border-right: 1px dashed #2a576e;
}

.container {
  max-width: 1320px;
  margin: 0 auto;
}

.imageWelcome {
  height: 596px;
  background: linear-gradient(105.32deg, rgba(38, 52, 137, 0.45) 36.89%, rgba(38, 52, 137, 0) 80.06%), url('../../../public/img/Image_WelcomePage.png');
  z-index: 3;
}

.imageWelcomeTitle {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 68px;
  color: #FFFFFF;
  max-width: 929px;
  padding-top: 200px;
  padding-left: 80px;
  margin-bottom: 40px;
}

.imageWelcomeDesc {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 44px;
  color: #FFFFFF;
  max-width: 929px;
  padding-left: 80px;
}

.benefitsContainer {
  padding: 80px 0;
}

.requests {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 120px;
}

.request {
  border: 1px solid rgba(123, 182, 225, 0.6);
  border-radius: 30px;
  padding: 42px 36px;
  display: flex;
  justify-content: center;
  /* width: 100%; */
  flex-direction: column;
  max-width: 440px;
}

.requestTitle {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-align: center;

  color: #1A2560;
  max-width: 319px;
  margin: 0 auto 32px auto;
}

.requestDesc {
  margin-bottom: 42px;
  max-width: 369px;
  margin: 0 auto 42px auto;
  text-align: center;
}

.requestButton {
  background-color: #263489;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */


  color: #FFFFFF;
}

.requestButton img {
  height: 20px;
  width: 16px;
}

.benefits>span {
  margin-bottom: 40px !important;
}

.benefitsTitle {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  /* identical to box height */


  color: #263489;
  margin-bottom: 40px;
}

.benefits {
  max-width: 1160px;
  margin: 0 auto;
  padding: 56px 25px;
}

.benefitsExtra {
  max-width: 1050px;
  margin: 0 auto;
  padding: 0;
}

.benefitsWrapper {
  background: rgba(38, 52, 137, 0.02);
  border-radius: 8px;
}

.benefit {
  border-top: 1px solid rgba(38, 52, 137, 0.06);
  padding: 36px 0 36px 0;
}

.Title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #1A2560;
  margin-bottom: 24px;
}

.Date {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #7BB6E1;
  margin-bottom: 16px;
}

.Desc {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #1A2560;

}

.serviceIcon {
  float: right;
  margin-left: 2rem;
}

.benefitCol {
  display: flex;
  align-items: center;
  height: 100%;
}

.announcementsContainer {
  background: rgba(38, 52, 137, 0.04);
  padding: 64px 0;
}

.announcements {
  max-width: 1320px;
  margin: 0 auto;
}

.announcements span,
.announcements a {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;

  color: #263489;
}

.faqContainer {
  padding: 120px 0;
  border-radius: 0;
}

.programs {
  /* padding: 120px 50px; */
  border-radius: 0;
  max-width: 1220px !important;
  margin: 0 auto !important;
}

.faq {
  border: none;
  border-radius: 0;
}

.faq div {
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  border: none !important;
}

.faq div>div {
  border: none !important;
}

.faq>div>div:nth-child(2) {
  box-shadow: none;
}

.faq>div>div:nth-child(1) {
  align-items: center !important;
}

.faq>div>div:nth-child(1)>div:nth-child(1) {
  box-shadow: none;
}

.faq>div>div:nth-child(1)>div:nth-child(1)>span {
  border: 1px solid rgba(38, 52, 137, 0.8) !important;
  border-radius: 25px;
  transform: rotate(90deg);
  padding: 12px !important;
  box-shadow: none;
}

.faq>div>div:nth-child(2)>div {
  border: none !important;
  padding: 0 !important;
  box-shadow: none !important;
}

.faq>div>div:nth-child(2)>div>div {
  box-shadow: none !important;
}

.faq div>div>div>div {
  border: none !important;
  padding: 32px;
}

.faq>div>div:nth-child(1)>span {
  padding: 32px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */


  color: #1A2560;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.gridbox {
  background-color: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  border-radius: 2px !important;
  height: 9rem;
  margin: 0;
  margin-right: 53px;
  margin-bottom: 50px;
  height: 286px;

}

.gridTop {
  height: 30%;
  border-radius: 2px 2px 0px 0px;
}

.gridBottom {
  border-radius: 0px 2px 2px 0px;
  padding: 16px;
}

.gridbox h3 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #1A2560;
  margin-top: 0%;
}

.gridbox span {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: rgba(26, 37, 96, 0.6);
}

.iconContainer {
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 6px;
  margin-top: -51px;
  max-width: 72px;
}

.iconContainer img {
  width: 60px !important;
  height: auto;
  margin: 0 auto !important;
  /* width: 72px;
  height: 72px; */
  display: flex;
}

.overlay_header {
  color: #eee;
  text-align: right;
  padding: 0 1rem 0 1rem;
}

.cross {
  font-size: 2rem !important;
  background-color: transparent;
  color: #122b52;
  border: none;
  cursor: pointer;
}

.okBtn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.submit, .okBtn {

  border-radius: 3px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #FFFFFF;
  padding: 1rem 2rem;
  height: 100%;

  cursor: pointer;
}

.submit:nth-child(2):hover {
  background: #263489 !important;
}

.uploadContainer {
  margin-top: 1rem;
  height: 10%;
}

.resetBtn {

  border-radius: 3px;
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  padding: 8px auto;
  height: 100%;
  margin-top: 68px;
  margin-bottom: 60px;
  margin-left: 20px;
  margin-right: 20px;
  width: 134px;
  height: 42px;
  cursor: pointer;
  color: #767CA0;
  box-shadow: none !important;
  border: 0;
}

.resetBtn:hover span {
  color: #767CA0;
}

.delete {
  border-radius: 2px;
  padding: 8px auto;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  width: 308px;
  height: 64px !important;
  margin: 0 auto;
  gap: 16px;
  margin-bottom: 2rem;

}

.forward {
  border-radius: 2px;
  padding: 8px auto;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  min-width: 308px;
  height: 64px !important;
  margin: 0 auto;
  gap: 16px;
  float: right;
}

/* .submit:hover {
  background-color: #263489 !important;
} */

.message {
  max-width: 540px;
  margin: 0 auto;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  /* or 180% */

  text-align: center;

  color: #1A2560;
}

.message h2 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  text-align: center;

  color: #1A2560;

  max-width: 540px;
  text-align: center;
}

.dangerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(237, 83, 80, 0.08);
  border-radius: 300px;
  height: 242px;
  width: 242px;
  margin: 0 auto 40px auto;
}

.notificationContainer {
  background: rgba(237, 83, 80, 0.08);
  border-radius: 2px;
  max-width: 1320px !important;
  margin: 0 auto !important;
  padding: 30px 40px;
  display: flex;
  align-items: center;
  gap: 24px;
}

.notificationContainer span {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #ED5350;
}


.mIdInput {
  border: 1px solid #2634894D !important;
  border-radius: 3px;
  background-color: #fff !important;
  margin-bottom: 60px;
  height: 60px;
  padding: 1rem;

  color: rgba(26, 37, 96, 0.60);
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.mIdInput::placeholder {
  background-color: #fff !important;
  color: #1A2560 !important;
  font-family: "Open Sans" !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !;
}


.mailInput {
  border-radius: 2px !important;
  border: 1px solid rgba(26, 37, 96, 0.24) !important;
  margin-bottom: 60px;
  /* height: 364px; */
  padding: 1rem;
  min-height: 364px;
  color: #1A2560;
  font-family: 'Open Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.mailInput span:last-child {
  align-items: flex-end;
}


.textArea {
  border: 1px solid #2634894D !important;
  border-radius: 2px;
  background-color: #2634890A;
  margin-bottom: 60px;
}

.buttonsContainer {
  display: flex;
  justify-content: space-evenly;
  gap: 5%;
  margin-left: -4em;
}

.titleContainer {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  gap: 40px;
  max-width: 638px;
}

.notificationContainer {
  background: #FED54014;
  border-radius: 2px;
  max-width: 1320px !important;
  margin: 0 auto !important;
  padding: 30px 40px;
  display: flex;
  gap: 24px;
}

.notificationContainer img {
  max-width: 48px;
}

.notificationContainer span {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #FED540;
}

.statusImg {
  width: 48px;
  height: 48px;
}

.radioGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 auto;
  max-width: 422px;
  padding-top: 20px;
}

.radioGroup label {
  display: flex;
  flex-direction: row-reverse;

  justify-content: space-between;

  color: #1A2560;
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.radioGroup label>span:nth-child(1) {
  border-radius: 27px;
  border: 1px solid rgba(38, 52, 137, 0.30);
  background: #FFF;
  padding: 4px !important
}

.radioGroup label>span>span:nth-child(2) {
  /* background-color: #7DB7E3 !important; */
  width: 12px !important;
  height: 12px !important;
  border: 0 !important;

}

.radioGroup ::after {
  display: none;
}

.imgDownload {
  border: 1px solid #7BB6E1;
  background: #7BB6E1;
  cursor: pointer;
}

.imgDownload:hover {
  transform: scale(1.02);
}

.fileContainer {
  /* height: 50px; */
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 25px;
  margin-bottom: 10px;
}

.fileLabels {
  display: flex;
  gap:27px
}

.fileButtons {
  display: flex;
  gap: 9px;
}

.menuActivitiesContainer {
  border-radius: 3px !important;
}

.menuActivities {
  border-radius: 3px;
  border: 1px solid rgba(38, 52, 137, 0.30);
  background: #FFF;

  border: 1px solid #2634894D !important;
  border-radius: 2px;
  background-color: #fff !important;
  margin-bottom: 60px;
  padding: 1rem;

  color: #1A2560;
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menuActivities:hover {
  color: #1A2560;
}

.menuActivities svg {
  color: #7BB6E1 !important;
}

.itemActivity {
  padding: 8px 12px !important;
}

.itemActivity span {
  color: #1A2560;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.itemActivity:hover {
  background: #7BB6E1 !important;
}

.itemActivity:hover span {
  color: #FFF;
}

.editIcon {
  cursor: pointer;
}

@media only screen and (max-width: 1430px) {
  .container {
    max-width: 90%;
  }
}

@media only screen and (max-width: 1199px) {
  .buttonsContainer {
    margin-left: 0;
  }
}

@media (max-width: 1024px) {
  .gridContainer {
    grid-template-columns: repeat(2, 1fr);
  }

}

@media (max-width: 767px) {
  .clabel2 {
    margin-top: 4rem;
    text-align: left;
    justify-content: flex-start;
  }

  .cres2 {
    justify-content: flex-start;
    text-align: left;
  }
}

@media only screen and (max-width: 600px) {

  .fcontainer {
    padding: 0 25px 50px 25px;
  }

  .title {
    font-size: 30px;
    padding: 0px;
  }

  .subTitle {
    font-size: 20px;
    font-weight: 500;
    padding: 20px 0px;
  }

  .hr {
    width: 0px;
    margin: 0px;
  }

  .subTitleText {
    margin-top: 20px;
    bottom: -20px;
    right: 210px;
  }

  .subTitleImage {
    bottom: -20px;
  }

  .TitlePosts,
  .PostCol {
    border-right-color: transparent !important;
  }

  .dashed {
    border-right: none !important;
  }

  .serviceIcon {
    margin-top: 20px;
    float: none;
  }

  .gridContainer {
    grid-template-columns: repeat(1, 1fr);
  }

  .cresFileTitle {
    width: 100%;
    display: block;
  }

  .delete {
    width: 100%;
    min-width: 100%;
  }

  .fileContainer {
    flex-direction: column;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 25px;
    margin-bottom: 46px;
  }

  .titleContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .forward {
    min-width: 0;
    height: 64px !important;
    margin: 0 auto;
    gap: 16px;
    float: right;
  }
}