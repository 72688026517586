.cookiesWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    background-color: #263489;
    position: sticky;
    bottom: 0;

    transition-timing-function: ease-in;
    transition: 0.5s;
    transform: translateY(130%);
}
  
.cookiesWrapperShow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    background-color: #263489;
    position: sticky;
    bottom: 0;

    transition-timing-function: ease-in;
    transition: 0.5s;
    transform: translateY(0);
}

.text {
    padding: 1rem;
    color: #FFFFFF
}

.button {
    padding: 0 1rem;
    margin-right: 10rem;
    background-color: #FFFFFF;
    border-radius: 2rem;
    cursor: pointer;
}