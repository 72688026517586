.container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 800px;
  padding-block: 30px;
  margin: 0 auto;
  text-align: justify;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.title {
  font-weight: 700;
}

.text {
  text-indent: 50px;
  line-height: 26px;
}

.heading-text {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}
