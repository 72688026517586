.carousel {
  padding: 57px 0 76px 0;
  width: 100% !important;
}

.carousel > div {
  width: 100% !important;
  /* max-height: 300px !important; */
}

.carousel > div > div > div {
  /* max-height: 100% !important; */
  max-height: 576px;
}

.carousel h2 {
  color: #323f5f;
  font-family: inherit;
  font-size: 1.8em;
  font-style: normal;
  font-weight: 600;
  padding-bottom: 12px;
  text-align: left;
}

.carouselslider {
  /* margin: 10px; */
  width: 90% !important;
  position: relative;
  margin: 0 auto;
}

.carouselslider a {
  color: #0f0f0f;
  text-decoration: none !important;
}

/* .carouselslider:hover .carouselimg img {
  filter: blur(4px);
  opacity: 0.8;
} */

/* .carouselslider:hover .carouseltxt {
  top: -14rem;
  transition: 0.6s;
} */

.carouselslider:hover .more {
  /* top: -13rem; */
  /* /* transition: 1s; */
  /* visibility: visible;*/
  /* animation: fadeIn 1s; */
  color: #1a2560;
  text-decoration: underline;
}

.carouselimg {
  width: 100%;
  position: relative;
  overflow: hidden;
  /* height: 463px; */
}

.carouselimg img {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  height: 15rem;
  object-fit: cover;
}

.carouseltxt {
  /* background: rgba(150, 129, 91, 0.2); */
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.61) 1.56%,
    #ffffff 53.65%
  );
  border-radius: 0px;
  position: relative;
  float: left;
  width: 100%;

  /* height: 170px; */
  /* top: -12rem; */
  padding: 24px 0;
}

.carouseltxt > span:first-child {
  padding: 17px;
}

.carouseltxt > span,
.carouseltxt > div {
  max-width: 89%;
  margin: 0 auto;
}

.carouselslider h3,
.carouselslider p img {
  padding: 10px;
  padding-bottom: 0px;
}

.carouselslider p {
  color: black !important;
  position: relative;
}

/* 
.carouselslider div {
  padding: 8px 16px 8px 0;
} */

.carouselslider p img {
  float: left;
  display: inline;
}

.carouselslider span {
  top: 0px;
  position: relative;
  text-align: left;
  width: 100%;
  float: left;

  /* padding: 12px; */
}

.description {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 38px;
}

.more {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #7bb6e1;
  text-align: right !important;
  cursor: pointer;

  margin-top: 15px;
  max-width: 89%;

  color: #7bb6e1;
}

.date {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 16px;
  color: #7bb6e1;
  margin-bottom: 16px;
}

.text {
  height: 234px;
  overflow: hidden;
  width: 100%;
  margin-bottom: 3.5vh;
}

.title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #1a2560;
  margin-bottom: 24px;
}

.carouselslider h3 {
  font-size: 20px;
  position: relative;
  vertical-align: middle;
  overflow: hidden;
  display: table-cell;
  text-align: right;
  width: 100%;
  float: left;
  margin-bottom: -2px;
  color: #323f5f;
  font-weight: bold;
}

.carouselslider p {
  font-size: 15px;
  /* height: 25px; */
  overflow: hidden;
  margin: 0;

  color: #1a2560;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.carousel h4 {
  display: inline;
  float: right;
  position: absolute;
  right: 10vw;
  font-size: 18px;
}

.carousel h4 a {
  text-decoration: none;
  color: #d55e2d !important;
}

.carousel h4 a:hover {
  color: #323f5f !important;
}

.carousel h4 span {
  color: #d55e2d !important;
  margin-left: 10px;
  background: #f9f2e6 !important;
  border-radius: 50px;
  font-size: 1em;
  width: 35px;
  height: 35px;
  line-height: 35px;
}

.carouselicon1 {
  width: 50px;
  height: 50px;
  background: rgb(213 94 45);
  padding: 6px;
  border-radius: 130px;
  position: absolute;
  left: -25px;
  overflow: visible;
}

.carouselicon {
  margin: 0 auto;
  padding: 0;
  position: absolute;
  left: 20%;
  top: 20%;
}

.carouselicon img {
  height: auto;
  width: 28px;
}

.carouselslider {
  width: 100%;
}

.top_banner_txt {
  width: 38%;
  height: 60vh;
  background: rgba(249, 242, 230, 0.7);
  box-shadow: 0px 1px 15px rgba(150, 129, 91, 0.2);
  backdrop-filter: blur(25px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 0px 0px 200px 0px;
}

.top_banner_txt div {
  padding: 90px;
  position: absolute;
  left: 0;
  top: 20%;
}

.top_banner_txt span {
  position: relative;
  float: left;
  margin: 10px;
  position: relative;
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  text-align: justify;
  color: #323f5f;
}

@media screen and (max-width: 781px) {
  .carousel {
    width: 100% !important;
    margin: 0 auto;
  }

  .carouselslider {
    background-color: #feffe8 !important;
    margin: 20px;
    /* width: 80% !important; */
    position: relative;
    margin: 0 auto;
  }

  .carouselicon img {
    padding: 10px;
    width: 100px;
  }

  .carousel h4 {
    margin-top: 34px;
  }

  .carousel {
    padding: 0px 0px 120px 0px;
  }

  .carousel h2 {
    font-size: 2rem;
    padding-top: 20px;
  }

  .carousel h4 {
    padding-right: 28px;
  }

  .carouseltxt {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.61) 1.56%,
      #ffffff 53.65%
    );
    border-radius: 0px;
    position: relative;
    float: left;
    width: 100%;

    /* height: 270px;
      top: -210px; */
  }

  /* .carousel>div>div>div:nth-child(1) {
      left: 36% !important;
      top: 90% !important;
      z-index: 999999999999;
    }
  
    .carousel>div>div>div:nth-child(3) {
      left: 50% !important;
      top: 90% !important;
      z-index: 999999999999;
    } */
}

@media screen and (max-width: 767px) {
  .carousel {
    padding: 33px 0px 33px 0px;
  }

  .title {
    font-size: 18px;
    margin-bottom: 16px;
  }

  .description {
    font-size: 16px;
  }

  .more {
    font-size: 14px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
