.btn {
  float: right;
  background: #7DB7E3;
  border-radius: 3px;
}

.btnlink {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  color: rgba(123, 182, 225, 0.6);
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
  float: right;
  display: block;
  width: 100%;
  text-align: right;
  cursor: pointer;
}

.btnlink:hover {
  color: #1A2560;
}

.fcontainer {
  position: relative;
  float: none;
  display: block;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 60px;
}

.clabel {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  color: #1A2560;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
  float: left;
  display: flex;
  width: 100%;
}

.cres {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  color: rgba(0, 0, 0, 0.6);
  ;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
  float: left;
  display: flex;
  width: 100%;
}

.formContainer {
  margin: auto;
  width: 30%;
  padding-bottom: 20px;
  margin-top: 50px;
  margin-bottom: 50px;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
}

.formContainer label {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem !important;
  color: #1A2560;
  position: relative;
  float: left;
  display: block;
}

.formContainer input {
  position: relative;
  float: left;
  display: block;
  border: 2px solid rgba(123, 182, 225, 0.6);
  border-radius: 3px;
}

.formupper {
  background: #7BB6E1;
  border-bottom: 6px solid #263489;
  padding: 20px;
  position: relative;
  float: none;
  display: block;
  font-size: 1.25rem;
}

.formupper img {
  position: relative;
}

.formupper span {
  color: white;
  font-weight: bold;
  text-align: right;
  position: absolute;
  padding-right: 20px;
  padding-left: 20px;
  font-family: 'Open Sans', sans-serif;
}

.formContainer1 {
  padding: 20px;
  position: relative;
  float: none;
  display: block;
}

.container {

  /* padding: 30px; */
  width: 100%;
  height: auto;
}

.container h2 {
  color: #1A2560;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.65rem;
}

.container>div>div>div {
  max-width: 100%;
}

.title {
  color: var(--blue);
  font-size: 44px;
  text-align: left;
  font-weight: 700;
  padding: 40px 0px 20px 0px;
  line-height: 1.2;
}

.subTitle {
  color: var(--blue);
  font-size: 22px;
  text-align: left;
  line-height: 1.3;
  padding: 20px 20px 10px 180px;
  white-space: break-spaces;
}

.subTitleText {
  color: var(--blue);
  font-size: 22px;
  text-align: right;
  position: absolute;
  bottom: 70px;
  right: 50px;
}

.subTitleImage {
  text-align: right;
  position: absolute;
  bottom: 0;
}

.hr {
  display: inline-block;
  width: 150px;
  margin-left: 0;
  height: 5px;
  border-width: 0;
  background-color: var(--blue);
  margin: 0px 30px 12px 0px;
}

.image {
  width: 110%;
  height: auto;
}


p {
  white-space: break-spaces;
}

.info {
  max-width: 1186px;
  background-color: rgba(42, 87, 110, 0.2);
  margin: 0 auto;
}

.TitlePosts {
  font-size: 16px;
  color: #2a576e;
  text-align: center !important;
  margin: 0 auto !important;
  background-color: #d4dce0;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .TitlePosts:nth-child(1) {
  border-right: 1px dashed #2a576e;
}
.TitlePosts:nth-child(2) {
  border-right: none !important;
} */

.titleSection {
  font-size: 16px;
  color: #fff;
  text-align: center !important;
  margin: 0 auto;
  padding: 13px 513px 14px 550px;
  background-color: #2a576e;
}

.textBold {
  padding: 78px 78px 0 78px;
  font-size: 29px;
  font-weight: bold;
  color: #2a576e;
}

.text {
  padding: 44px 78px 44px 79px;
  font-size: 16px;
}

.Post {
  padding: 47px 47px;
  max-width: 489px;
  margin: 0 auto;
  /* min-height: 473px; */
}

.ImagePost {
  /* max-height: 324px; */
  max-height: 269px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  max-width: 100%;
}

.Date {
  font-size: 16px;
  color: #696e71;
  margin: 10px auto;
}

.TitlePost {
  font-size: 20px;
  font-weight: 500;
  color: #2a576e;
}

.ExcerptPost {
  font-size: 16px;
  color: #0e0f0f;
  margin-top: 10px;
  margin-right: 6px;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.PostBtn {
  height: 46px;
  flex-grow: 0;
  border-radius: 14px !important;
  background-color: #2a576e;
  margin-top: 20px;
  letter-spacing: 1.82px;
  color: #fff;
  font-size: 11px !important;
  padding: 0 19px !important;
}

.PostBtn span {
  font-family: "Book", Arial, serif !important;
  font-size: 11px;
  letter-spacing: 1.82px;
  color: #FFF;
}

.PostBtn:hover,
.PostBtn:focus {
  /* box-shadow: 0 0.1em 0.5em -0.4em white; */
  /* transform: translateY(-0.1em); */
  border-bottom: 1px solid transparent;
  /* transform: translateX(0.2em); */
  /* border-color: #fff !important; */

  -webkit-transform: scale(0.95) !important;
  -moz-transform: scale(0.95) !important;
  -ms-transform: scale(0.95) !important;
  -o-transform: scale(0.95) !important;
  transform: scale(0.95) !important;
  transition: .4s;
}

.pagination {
  margin-top: 55px;
  display: flex !important;
  justify-content: center !important;
}

.pagination li,
.ant-pagination-item-active:active {
  min-height: 47px;
  min-width: 47px !important;
  height: 36px;
  /* background: transparent !important; */
  display: grid !important;
  align-items: center;
  border-color: #bcbcbc !important;
}

.pagination li:first-child,
.pagination li:last-child {
  display: none !important;
}

.pagination a {
  color: #bcbcbc !important;
}

.pagination .ant-pagination-item-active {
  background-color: #2a576e !important;
}

li>div {
  max-width: 100%;
}

.dashed {
  border-right: 1px dashed #2a576e;
}

.container {
  max-width: 1320px;
  margin: 0 auto;
  margin-bottom: 13.5rem;
}

.containerAdmin {
  background-color: #F6F7FA;
  padding: 40px;

}

.containerAdmin>div:nth-child(1) {
  max-width: 1416px;
}

.imageWelcome {
  height: 596px;
  background: linear-gradient(105.32deg, rgba(38, 52, 137, 0.45) 36.89%, rgba(38, 52, 137, 0) 80.06%), url('../../../public/img/Image_WelcomePage.png');
  z-index: 3;
}

.imageWelcomeTitle {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 68px;
  color: #FFFFFF;
  max-width: 929px;
  padding-top: 200px;
  padding-left: 80px;
  margin-bottom: 40px;
}

.imageWelcomeDesc {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 44px;
  color: #FFFFFF;
  max-width: 929px;
  padding-left: 80px;
}

.benefitsContainer {
  padding: 80px 0;
}

.requests {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 120px;
}

.request {
  border: 1px solid rgba(123, 182, 225, 0.6);
  border-radius: 30px;
  padding: 42px 36px;
  display: flex;
  justify-content: center;
  /* width: 100%; */
  flex-direction: column;
  max-width: 440px;
}

.requestTitle {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-align: center;

  color: #1A2560;
  max-width: 319px;
  margin: 0 auto 32px auto;
}

.requestDesc {
  margin-bottom: 42px;
  max-width: 369px;
  margin: 0 auto 42px auto;
  text-align: center;
}

.requestButton {
  background-color: #263489;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */


  color: #FFFFFF;
}

.requestButton img {
  height: 20px;
  width: 16px;
}

.benefits>span {
  margin-bottom: 40px !important;
}

.benefitsTitle {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  /* identical to box height */


  color: #1A2560;
  margin-bottom: 40px;
}

.benefits {
  max-width: 1160px;
  margin: 0 auto;
  padding: 56px 25px;
}

.benefitsExtra {
  max-width: 1050px;
  margin: 0 auto;
  padding: 0;
}

.benefitsWrapper {
  background: rgba(38, 52, 137, 0.02);
  border-radius: 8px;
}

.benefit {
  border-top: 1px solid rgba(38, 52, 137, 0.06);
  padding: 36px 0 36px 0;
}

.Title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #1A2560;
  margin-bottom: 24px;
}

.Date {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #7BB6E1;
  margin-bottom: 16px;
}

.Desc {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #1A2560;

}

.serviceIcon {
  float: right;
  margin-left: 2rem;
}

.benefitCol {
  display: flex;
  align-items: center;
  height: 100%;
}

.announcementsContainer {
  background: rgba(38, 52, 137, 0.04);
  padding: 64px 0;
}

.announcements {
  max-width: 1320px;
  margin: 0 auto;
}

.announcements span,
.announcements a {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;

  color: #263489;
}

.faqContainer {
  padding: 120px 0;
  border-radius: 0;
}

.faq {
  border: none;
  border-radius: 0;
}

.faq div {
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  border: none !important;
}

.faq div>div {
  border: none !important;
}

.faq>div>div:nth-child(2) {
  box-shadow: none;
}

.faq>div>div:nth-child(1) {
  align-items: center !important;
}

.faq>div>div:nth-child(1)>div:nth-child(1) {
  box-shadow: none;
}

.faq>div>div:nth-child(1)>div:nth-child(1)>span {
  border: 1px solid rgba(38, 52, 137, 0.8) !important;
  border-radius: 25px;
  transform: rotate(90deg);
  padding: 12px !important;
  box-shadow: none;
}

.faq>div>div:nth-child(2)>div {
  border: none !important;
  padding: 0 !important;
  box-shadow: none !important;
}

.faq>div>div:nth-child(2)>div>div {
  box-shadow: none !important;
}

.faq div>div>div>div {
  border: none !important;
  padding: 32px;
}

.faq>div>div:nth-child(1)>span {
  padding: 32px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */


  color: #1A2560;
}

.statCol {
  padding-bottom: 40px;

}

.statCol:first-child {
  border-bottom: 6px solid #263489;
  margin-inline-start: 0 !important;
}

.statCol:nth-child(2) {
  border-bottom: 6px solid #7BB6E1;
}

.statCol:last-child {
  border-bottom: 6px solid #FED540;
}

.statInnerCol {
  border-left: 1px solid #2634891F;
  padding: 0 60px 0 12px;
}

.stat {
  font-family: 'Open Sans';
  font-size: 36px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 24px;
}

.statTitle {
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0;
  padding-bottom: 0;
}

.editButton {
  max-width: 308px;
  cursor: pointer;
}

.innerRight {
  float: right;
}



.search {
  border-radius: 2px !important;
  border: 1px solid rgba(38, 52, 137, 0.12) !important;
  background: #FFF !important;
  align-items: center;
  height: 64px;

  padding-right: 15px;
  /* Adjust this value based on your image width */
  width: 100%;
  box-sizing: border-box;
  z-index: 0;
}

.search ::placeholder {
  color: #26348999 !important;
}

.search>span>span {
  font-size: 18px !important;
}

.search input {
  box-shadow: none !important;
  color: rgba(38, 52, 137, 0.60);
  font-family: Open Sans;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.search input,
.search button {
  height: 62px !important;
  border-radius: 2px !important;
  border: none;
  background: #FFF !important;
}

.rangePicker {
  height: 64px !important;
  /* width: ; */
  margin-left: 24px;
  border-radius: 2px;
  border: 1px solid rgba(38, 52, 137, 0.12) !important;
  background: #FFF !important;
}

.rangePicker ::placeholder {
  color: #26348999 !important;
}

.rangePicker svg {
  color: #26348999 !important;
}

.rangePicker input {
  /* color: #263489 !important; */
  color: #7D85B8 !important;
  font-family: 'Open Sans';
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal !important;
}

.rangePicker span>span {
  font-size: 18px !important;
}

.dropdown {
  margin-top: -101px;
  float: right;
  border: 1px solid #263489;

  width: 133px;
  height: 48px;
  border-radius: 31px;
  display: flex;
  justify-content: center;
  color: #263489;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  cursor: pointer;
}

.statContainer>div {
  justify-content: space-between !important;
}

.editWrap {
  width: 48px;
  height: 48px;
  background-color: #EEEFF6;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.editTransparentBlue {
  width: 24px;
  height: 24px;
}

.addButton {
  /* transform: translateY(-1.35rem); */
  border: none;
  padding: 1.135rem;
  font-family: "Open Sans";
  color: #FFFFFF;
  font-size: 1.25rem;
  background-color: #4a64f8;
  align-items: center;
  margin-left: 5.75rem;
  cursor: pointer;
}

.deleteButton {
  /* transform: translateY(-1.35rem); */
  border: none;
  padding: 1.135rem;
  font-family: "Open Sans";
  color: #FFFFFF;
  font-size: 1.25rem;
  background-color: #f84a4a;
  align-items: center;
  cursor: pointer;
}

.deleteButtonDisabled {
  /* transform: translateY(-1.35rem); */
  border: none;
  padding: 1.135rem;
  font-family: "Open Sans";
  color: #FFFFFF;
  font-size: 1.25rem;
  background-color: #b89393;
  align-items: center;
}

.statusContainer {
  display: flex;
  align-items: center;
  padding-left: 10%;
  width: 100%;
}

.statusRow .statusContainer {
  display: flex;
  align-items: center;
  padding-left: 10%;
  width: 104%;
}

.statusRow p:not(:last-child) {
  text-align: left;
  width: 30%;
}

.statusRow {
  display: flex;
  gap: 5%;
  max-width: 89%;
  /* align-items: center; */
  justify-content: space-between;


  color: #1A2560;
  font-family: "Open Sans";
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.tabpane>div:first-child:before {
  border-bottom: 1px solid #C4C8DF;
}

.tabpane {
  min-width: 200px;
}

.tabTab {
  color: rgba(26, 37, 96, 0.60) !important;
  font-family: 'Open Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 65px !important;
}

.tabpane>div {
  margin-bottom: 40px !important;
}

.tabTab:hover {
  color: #7BB6E1 !important;
}

.activetab {
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 20px;
  line-height: normal;
  color: #263489;
  font-weight: 700;
  padding: 0 65px;
}

.activetab div {
  color: yellow;
  border: 1px solid yellow;
}

.tabpane>div:nth-child(1)>div:nth-child(1)>div>div:last-child {
  background: #263489;
}

.tableData th {
  color: #1A2560;
  text-align: center;
  font-family: 'Open Sans' !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  text-align: center !important;
  padding: 36px 36px !important;
  background: #FFF !important;
  border: 1px solid #DCDFEC !important;
  z-index: 0;
  /* 
  color: #1A2560 !important;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal; */
}

.tableData td {
  text-align: center;

  border: 1px solid #DCDFEC !important;

  color: #1A2560;
  text-align: center;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tableData td>div {
  display: flex;
  margin: 0 auto;
}

.tableData td>label {
  display: flex;
  justify-content: center;
  padding-left: 0 !important;
  margin: 0 auto;
}

.noData {
  margin: 0 auto;
}

.exclamation {
  margin-top: 2rem;
}

.radioGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 auto;
  /* max-width: 415px; */
  margin-bottom: 50px;
}

.radioGroup label {
  display: flex;
  flex-direction: row-reverse;

  justify-content: space-between;

  color: #1A2560;
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.radioGroup label>span:nth-child(1) {
  border-radius: 27px;
  border: 1px solid rgba(38, 52, 137, 0.30);
  background: #FFF;
  padding: 4px !important
}

.radioGroup label>span>span:nth-child(2) {
  /* background-color: #7DB7E3 !important; */
  width: 12px !important;
  height: 12px !important;
  border: 0 !important;

}

.radioGroup ::after {
  display: none;
}

.modal1Container {
  width: 673px !important;
}

.modal1 {
  max-width: 538px;
  margin: 0 auto;
}

.modal1 h3 {
  color: #1A2560;
  font-family: 'Open Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.submit {
  border-radius: 3px;
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  padding: 8px auto;
  height: 100%;
  margin-bottom: 30px;

  width: 134px;
  height: 42px;
  cursor: pointer;
}

.submit:nth-child(2) {
  margin-right: 4%;
}

.menuExport {
  padding: 0 !important;
}

.menuItemExport {
  padding: 7px !important;
  text-align: center !important;
  color: #1A2560 !important;
  text-align: center !important;
  font-family: "Open Sans" !;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  border-radius: 0px !important;
}

.menuItemExport li {
  border-radius: 0 !important;
}

.menuItemExport:hover {
  background-color: #7BB6E1 !important;

}

.menuItemExport:nth-child(1):hover {
  background-color: #7BB6E1 !important;
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important;
}

.menuItemExport:nth-child(2):hover {
  background-color: #7BB6E1 !important;
  border-bottom-left-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

.menuItemExport:hover span {
  color: white;
}

.searchIcon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.customSearchContainer {
  position: relative;
  display: inline-block;
}

button {
  opacity: 1 !important;
  display: inline-flex;
}

@media only screen and (max-width: 1999px) {
  .statCol {
    margin-bottom: 2rem;
    padding-bottom: 1rem;
  }

  .statInnerCol {
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width:991px) {
  .statCol {
    margin-inline-start: 0 !important;
  }
}

@media only screen and (max-width: 939px) {
  .rangePicker {
    margin-left: 0;
    width: 100%;
  }

  .search {
    width: 100% !important;
    margin-bottom: 7px;
  }

  .innerRight {
    float: none;
  }
}

@media only screen and (max-width: 609px) {
  .rangePicker {
    margin-left: 0;
    width: 100%;
  }

  .search {
    width: 100% !important;
    margin-bottom: 7px;
  }
}

@media only screen and (max-width: 575px) {

  .title {
    font-size: 30px;
    padding: 0px;
  }

  .subTitle {
    font-size: 20px;
    font-weight: 500;
    padding: 20px 0px;
  }

  .hr {
    width: 0px;
    margin: 0px;
  }

  .subTitleText {
    margin-top: 20px;
    bottom: -20px;
    right: 210px;
  }

  .subTitleImage {
    bottom: -20px;
  }

  .TitlePosts,
  .PostCol {
    border-right-color: transparent !important;
  }

  .dashed {
    border-right: none !important;
  }

  .serviceIcon {
    margin-top: 20px;
    float: none;
  }

  .statusRow {
    display: block;
    justify-content: space-between;
    max-width: 80%;
  }

  .statusRow div:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    height: 16px !important;
  }



  .statusRow p:not(:last-child) {
    text-align: left;
    width: 100% !important;

  }

  .statusContainer {
    padding-left: 0% !important;
  }

  .editButton {
    max-width: 100%;
  }

  .dropdownContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }



  .dropdown {
    margin-top: -11rem;
    border: 1px solid #263489;
    position: absolute;
    width: 133px;
    height: 48px;
    border-radius: 31px;
    display: flex;
    justify-content: center;
    color: #263489;
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    cursor: pointer;
  }

  .containerAdmin .container {
    margin-top: 6rem;
  }

  .submit {
    border-radius: 3px;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    padding: 8px auto;
    height: 100%;
    margin-bottom: 15px;

    width: 134px;
    height: 42px;
    cursor: pointer;

  }

  .submit:nth-child(2) {
    margin-right: 0%;
  }

  .modal1Container>div:nth-child(2)>div:last-child {
    display: flex !important;
    justify-content: center !important;
  }
}