.btn {
    float: right;
    background: #7DB7E3;
    border-radius: 3px;
}

.formContainer {
    margin: auto;
    width: 45%;
    padding-bottom: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
}

.formContainer label {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem !important;
    color: #1A2560;
    position: relative;
    float: left;
    display: block;
}

.formContainer input {
    position: relative;
    float: left;
    display: block;
    border: 2px solid rgba(123, 182, 225, 0.6);
    border-radius: 3px;
}

.formupper {
    background: #7BB6E1;
    border-bottom: 6px solid #263489;
    padding: 20px;
    position: relative;
    float: none;
    display: block;
    font-size: 1.25rem;
}

.formupper img {
    position: relative;
}

.formupper span {
    color: white;
    font-weight: bold;
    text-align: right;
    position: absolute;
    padding-right: 20px;
    padding-left: 20px;
    font-family: 'Open Sans', sans-serif;
}

.formContainer1 {
    padding: 20px;
    position: relative;
    float: none;
    display: block;
}

.container {
    /* margin-top: 40px; */
    /* padding-bottom: 10px; */
    width: 100%;
    /* height: auto; */
}

.container>div>div>div {
    max-width: 100%;
}



.etpa {
    border-radius: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    /* float: left; */
    /* margin: 40px 20px; */
    margin: 160px auto 0 auto;
    /* height: 598px; */
    background: #ffffff;
    box-shadow: 0px 0px 8px 0px #00000029;
    width: 624px;
    max-width: 89%;
}

.login {
    border-radius: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    /* float: left; */
    /* margin: 40px 20px; */
    margin: 0 auto;
    /* height: 598px; */
    background: #ffffff;
    box-shadow: 0px 0px 8px 0px #00000029;
    width: 624px;
    max-width: 89%;
}

.login button {
    float: right;
    background: #7DB7E3 !important;
    border-radius: 3px;

}

/* .login img {
    margin-bottom: 50px;
} */

.title {
    color: var(--blue);
    font-size: 22px;
    text-align: left;
    font-weight: 700;
    padding: 20px 0;
    line-height: 1.2;
    background-color: #7BB6E1;
    padding: 13px 42px 15px 13px;
    /* height: 100%; */
    /* max-height: 136px; */
}

.title h3 {
    text-align: end;
    color: #FFF;
    font-family: "Open Sans";
    font-size: 24px;
    font-weight: 600;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: right;
    margin: 0;
}

.subTitle {
    color: var(--blue);
    font-size: 22px;
    text-align: left;
    line-height: 1.3;
    padding: 20px 20px 10px 180px;
    white-space: break-spaces;
}

.subTitleText {
    color: var(--blue);
    font-size: 22px;
    text-align: right;
    position: absolute;
    bottom: 70px;
    right: 50px;
}

.subTitleImage {
    text-align: right;
    position: absolute;
    bottom: 0;
}

.hr {
    display: inline-block;
    width: 150px;
    margin-left: 0;
    height: 5px;
    border-width: 0;
    background-color: var(--blue);
    margin: 0px 30px 12px 0px;
}

.image {
    width: 110%;
    height: auto;
}


p {
    white-space: break-spaces;
}

.info {
    max-width: 1186px;
    background-color: rgba(42, 87, 110, 0.2);
    margin: 0 auto;
}

.TitlePosts {
    font-size: 16px;
    color: #2a576e;
    text-align: center !important;
    margin: 0 auto !important;
    background-color: #d4dce0;
    height: 51px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .TitlePosts:nth-child(1) {
    border-right: 1px dashed #2a576e;
}
.TitlePosts:nth-child(2) {
    border-right: none !important;
} */

.titleSection {
    font-size: 16px;
    color: #fff;
    text-align: center !important;
    margin: 0 auto;
    padding: 13px 513px 14px 550px;
    background-color: #2a576e;
}

.textBold {
    padding: 78px 78px 0 78px;
    font-size: 29px;
    font-weight: bold;
    color: #2a576e;
}

.text {
    padding: 44px 78px 44px 79px;
    font-size: 16px;
}

.Post {
    padding: 47px 47px;
    max-width: 489px;
    margin: 0 auto;
    /* min-height: 473px; */
}

.ImagePost {
    /* max-height: 324px; */
    max-height: 269px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    max-width: 100%;
}

.Date {
    font-size: 16px;
    color: #696e71;
    margin: 10px auto;
}

.TitlePost {
    font-size: 20px;
    font-weight: 500;
    color: #2a576e;
}

.ExcerptPost {
    font-size: 16px;
    color: #0e0f0f;
    margin-top: 10px;
    margin-right: 6px;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.PostBtn {
    height: 46px;
    flex-grow: 0;
    border-radius: 14px !important;
    background-color: #2a576e;
    margin-top: 20px;
    letter-spacing: 1.82px;
    color: #fff;
    font-size: 11px !important;
    padding: 0 19px !important;
}

.PostBtn span {
    font-family: "Book", Arial, serif !important;
    font-size: 11px;
    letter-spacing: 1.82px;
    color: #FFF;
}

.PostBtn:hover,
.PostBtn:focus {
    /* box-shadow: 0 0.1em 0.5em -0.4em white; */
    /* transform: translateY(-0.1em); */
    border-bottom: 1px solid transparent;
    /* transform: translateX(0.2em); */
    /* border-color: #fff !important; */

    -webkit-transform: scale(0.95) !important;
    -moz-transform: scale(0.95) !important;
    -ms-transform: scale(0.95) !important;
    -o-transform: scale(0.95) !important;
    transform: scale(0.95) !important;
    transition: .4s;
}

.pagination {
    margin-top: 55px;
    display: flex !important;
    justify-content: center !important;
}

.pagination li,
.ant-pagination-item-active:active {
    min-height: 47px;
    min-width: 47px !important;
    height: 36px;
    /* background: transparent !important; */
    display: grid !important;
    align-items: center;
    border-color: #bcbcbc !important;
}

.pagination li:first-child,
.pagination li:last-child {
    display: none !important;
}

.pagination a {
    color: #bcbcbc !important;
}

.pagination .ant-pagination-item-active {
    background-color: #2a576e !important;
}

li>div {
    max-width: 100%;
}

.dashed {
    border-right: 1px dashed #2a576e;
}

.container {
    /* max-width: 1320px; */
    margin: 0 auto;
    background-color: #F6F7FA;
    padding: 40px 0;
}

.imageWelcome {
    height: 596px;
    background: linear-gradient(105.32deg, rgba(38, 52, 137, 0.45) 36.89%, rgba(38, 52, 137, 0) 80.06%), url('../../../public/img/Image_WelcomePage.png');
    z-index: 3;
}

.imageWelcomeTitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 68px;
    color: #FFFFFF;
    max-width: 929px;
    padding-top: 200px;
    padding-left: 80px;
    margin-bottom: 40px;
}

.imageWelcomeDesc {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 44px;
    color: #FFFFFF;
    max-width: 929px;
    padding-left: 80px;
}

.benefitsContainer {
    padding: 80px 0;
}

.requests {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 120px;
}

.request {
    border: 1px solid rgba(123, 182, 225, 0.6);
    border-radius: 30px;
    padding: 42px 36px;
    display: flex;
    justify-content: center;
    /* width: 100%; */
    flex-direction: column;
    max-width: 440px;
}

.requestTitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    text-align: center;

    color: #1A2560;
    max-width: 319px;
    margin: 0 auto 32px auto;
}

.requestDesc {
    margin-bottom: 42px;
    max-width: 369px;
    margin: 0 auto 42px auto;
    text-align: center;
}

.requestButton {
    background-color: #263489;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */


    color: #FFFFFF;
}

.requestButton img {
    height: 20px;
    width: 16px;
}

.benefits>span {
    margin-bottom: 40px !important;
}

.benefitsTitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    /* identical to box height */


    color: #1A2560;
    margin-bottom: 40px;
}

.benefits {
    max-width: 1160px;
    margin: 0 auto;
    padding: 56px 25px;
}

.benefitsExtra {
    max-width: 1050px;
    margin: 0 auto;
    padding: 0;
}

.benefitsWrapper {
    background: rgba(38, 52, 137, 0.02);
    border-radius: 8px;
}

.benefit {
    border-top: 1px solid rgba(38, 52, 137, 0.06);
    padding: 36px 0 36px 0;
}

.Title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: #1A2560;
    margin-bottom: 24px;
}

.Date {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #7BB6E1;
    margin-bottom: 16px;
}

.Desc {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #1A2560;

}

.serviceIcon {
    float: right;
    margin-left: 2rem;
}

.benefitCol {
    display: flex;
    align-items: center;
    height: 100%;
}

.announcementsContainer {
    background: rgba(38, 52, 137, 0.04);
    padding: 64px 0;
}

.announcements {
    max-width: 1320px;
    margin: 0 auto;
}

.announcements span,
.announcements a {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;

    color: #263489;
}

.faqContainer {
    padding: 120px 0;
    border-radius: 0;
}

.faq {
    border: none;
    border-radius: 0;
}

.faq div {
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
    border: none !important;
}

.faq div>div {
    border: none !important;
}

.faq>div>div:nth-child(2) {
    box-shadow: none;
}

.faq>div>div:nth-child(1) {
    align-items: center !important;
}

.faq>div>div:nth-child(1)>div:nth-child(1) {
    box-shadow: none;
}

.faq>div>div:nth-child(1)>div:nth-child(1)>span {
    border: 1px solid rgba(38, 52, 137, 0.8) !important;
    border-radius: 25px;
    transform: rotate(90deg);
    padding: 12px !important;
    box-shadow: none;
}

.faq>div>div:nth-child(2)>div {
    border: none !important;
    padding: 0 !important;
    box-shadow: none !important;
}

.faq>div>div:nth-child(2)>div>div {
    box-shadow: none !important;
}

.faq div>div>div>div {
    border: none !important;
    padding: 32px;
}

.faq>div>div:nth-child(1)>span {
    padding: 32px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */


    color: #1A2560;
}

.separator {
    height: 8px;
    background-color: #263489;
}

.logo {
    width: 215px;
    height: auto;
}

.customNotification {
    z-index: 9999999999;
}

@media only screen and (max-width: 575px) {
    .logo {
        display: none;
    }

    .title {
        font-size: 30px;
    }

    .subTitle {
        font-size: 20px;
        font-weight: 500;
        padding: 20px 0px;
    }

    .hr {
        width: 0px;
        margin: 0px;
    }

    .subTitleText {
        margin-top: 20px;
        bottom: -20px;
        right: 210px;
    }

    .subTitleImage {
        bottom: -20px;
    }

    .TitlePosts,
    .PostCol {
        border-right-color: transparent !important;
    }

    .dashed {
        border-right: none !important;
    }

    .serviceIcon {
        margin-top: 20px;
        float: none;
    }

    .login {
        height: 95%;
        max-width: 89%;
    }

    .formContainer {
        padding: 40px 22px;
    }

    .etpa {
        margin-top: 55px;
    }
}