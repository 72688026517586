.container404 {
  height: 55vh;
  width: 100%;
  background: linear-gradient(
      105.32deg,
      rgba(38, 52, 137, 0.45) 36.89%,
      rgba(38, 52, 137, 0) 80.06%
    ),
    url("../../../public/img/welcome.png") no-repeat center;
  background-size: cover;
  z-index: 3;
}

.container404 .textWrapper {
  top: 10%;
  left: 50%;
  height: 100px;
  padding-top: 12vh;
  margin: 0 auto;
}

.container404 h1 {
  margin: 0;
  text-align: center;
  color: #fff;
  font-family: arial;
  font-size: 50px;
  font-weight: 600;

  font-family: "Open Sans";
  font-style: normal;
  line-height: 136%;
  letter-spacing: -0.023em;
}

.container404 h3 {
  margin: 0 auto;
  text-align: center;
  color: white;
  font-size: 28px;
  line-height: 2.2rem;
  font-family: "ZonaPro";
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.023em;
  max-width: 86%;
}

.container404 .link {
  text-align: center;
  padding-top: 30px;
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
  font-family: "Open Sans";
}

.container404 .link a {
  color: #263489;
  font-family: "Open Sans";
  text-decoration: underline;
}

.container404.link a:hover {
  color: #fff;
}

.container404 p {
  font-family: "Open Sans";
}

@media screen and (max-width: 680px) {
  .container404 {
    height: 70vh;
  }

  .container404 h1 {
    font-size: 50px;
  }

  .container404 h2 {
    font-size: 42px;
  }

  .container404 h3 {
    font-size: 22px;
    padding: 2px 14px;
    line-height: 2.2rem;
  }
}
