.container {
  /* margin-top: 40px; */
  padding-bottom: 10px;
  width: 100%;
  height: auto;
  background-color: #ffffff;
}

.imageContainer {
  margin-bottom: 0;
}

.imageContainer,
.imageContainer {
  margin: 0 auto;
  /* max-width: 1320px; */
}

.container > div > div > div {
  max-width: 100%;
}

.title {
  color: var(--blue);
  font-size: 44px;
  text-align: left;
  font-weight: 700;
  padding: 40px 0px 20px 0px;
  line-height: 1.2;
}

.subTitle {
  color: var(--blue);
  font-size: 22px;
  text-align: left;
  line-height: 1.3;
  padding: 20px 20px 10px 180px;
  white-space: break-spaces;
}

.subTitleText {
  color: var(--blue);
  font-size: 22px;
  text-align: right;
  position: absolute;
  bottom: 70px;
  right: 50px;
}

.subTitleImage {
  text-align: right;
  position: absolute;
  bottom: 0;
}

.hr {
  display: inline-block;
  width: 150px;
  margin-left: 0;
  height: 5px;
  border-width: 0;
  background-color: var(--blue);
  margin: 0px 30px 12px 0px;
}

.image {
  width: 110%;
  height: auto;
}

p {
  white-space: break-spaces;
}

.info {
  max-width: 1186px;
  background-color: rgba(42, 87, 110, 0.2);
  margin: 0 auto;
}

.TitlePosts {
  font-size: 16px;
  color: #2a576e;
  text-align: center !important;
  margin: 0 auto !important;
  background-color: #d4dce0;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .TitlePosts:nth-child(1) {
    border-right: 1px dashed #2a576e;
}
.TitlePosts:nth-child(2) {
    border-right: none !important;
} */

.titleSection {
  font-size: 16px;
  color: #fff;
  text-align: center !important;
  margin: 0 auto;
  padding: 13px 513px 14px 550px;
  background-color: #2a576e;
}

.textBold {
  padding: 78px 78px 0 78px;
  font-size: 29px;
  font-weight: bold;
  color: #2a576e;
}

.text {
  padding: 44px 78px 44px 79px;
  font-size: 16px;
}

.Post {
  padding: 47px 47px;
  max-width: 489px;
  margin: 0 auto;
  /* min-height: 473px; */
}

.ImagePost {
  /* max-height: 324px; */
  max-height: 269px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  max-width: 100%;
}

.Date {
  font-size: 16px;
  color: #696e71;
  margin: 10px auto;
}

.TitlePost {
  font-size: 20px;
  font-weight: 500;
  color: #2a576e;
}

.ExcerptPost {
  font-size: 16px;
  color: #0e0f0f;
  margin-top: 10px;
  margin-right: 6px;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.PostBtn {
  height: 46px;
  flex-grow: 0;
  border-radius: 14px !important;
  background-color: #2a576e;
  margin-top: 20px;
  letter-spacing: 1.82px;
  color: #fff;
  font-size: 11px !important;
  padding: 0 19px !important;
}

.PostBtn span {
  font-family: "Book", Arial, serif !important;
  font-size: 11px;
  letter-spacing: 1.82px;
  color: #fff;
}

.PostBtn:hover,
.PostBtn:focus {
  /* box-shadow: 0 0.1em 0.5em -0.4em white; */
  /* transform: translateY(-0.1em); */
  border-bottom: 1px solid transparent;
  /* transform: translateX(0.2em); */
  /* border-color: #fff !important; */

  -webkit-transform: scale(0.95) !important;
  -moz-transform: scale(0.95) !important;
  -ms-transform: scale(0.95) !important;
  -o-transform: scale(0.95) !important;
  transform: scale(0.95) !important;
  transition: 0.4s;
}

.pagination {
  margin-top: 55px;
  display: flex !important;
  justify-content: center !important;
}

.pagination li,
.ant-pagination-item-active:active {
  min-height: 47px;
  min-width: 47px !important;
  height: 36px;
  /* background: transparent !important; */
  display: grid !important;
  align-items: center;
  border-color: #bcbcbc !important;
}

.pagination li:first-child,
.pagination li:last-child {
  display: none !important;
}

.pagination a {
  color: #bcbcbc !important;
}

.pagination .ant-pagination-item-active {
  background-color: #2a576e !important;
}

li > div {
  max-width: 100%;
}

.dashed {
  border-right: 1px dashed #2a576e;
}

.container {
  max-width: 1320px;
  margin: 0 auto;
}

.imageWelcome {
  /* height: 685px; */
  height: 618px;
  width: 100%;
  background: linear-gradient(
      105.32deg,
      rgba(38, 52, 137, 0.45) 36.89%,
      rgba(38, 52, 137, 0) 80.06%
    ),
    url("../../../public/img/welcome.png") no-repeat center;
  background-size: cover;
  z-index: 3;
}

.imageWelcomeTitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 68px;
  color: #ffffff;
  /* padding-top: 80px; */
  padding-top: 40px;
  /* padding-left: 80px; */
  margin-bottom: 42px !important;
  max-width: 1320px;
  margin: 0 auto;
}

.imageWelcomeTitle span,
.imageWelcomeDesc span,
.imageWelcomeDesc2 span {
  max-width: 913px !important;
  display: inline-block;
}

.imageWelcomeDesc {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 44px;
  color: #ffffff;
  /* max-width: 929px; */
  /* padding-left: 80px; */
  margin-bottom: 42px !important;
  max-width: 1320px;
  margin: 0 auto;
}

.imageWelcomeDesc2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #ffffff;
  /* max-width: 929px; */
  /* padding-left: 80px; */
  margin-bottom: 42px !important;
  max-width: 1320px;
  margin: 0 auto;
}

.benefitsContainer {
  padding: 80px 0;
}

.requests {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 80px;
  gap: 100px;
}

.request {
  border: 1px solid rgba(123, 182, 225, 0.6);
  border-radius: 30px;
  padding: 42px 36px;
  display: flex;
  justify-content: center;
  /* width: 100%; */
  flex-direction: column;
  max-width: 440px;
  box-shadow: 0px 0px 8px 0px #00000014;
  margin-top: -16rem;
  background-color: #fff;
  justify-content: space-between;
}

.requestTitle {
  color: #1a2560;
  max-width: 204px;
  margin: 0 auto 32px auto;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
}

.requestDesc {
  margin-bottom: 42px;
  max-width: 369px;
  margin: 0 auto 42px auto;
  text-align: center;

  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
}

.requestButton {
  background-color: #263489;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  width: 216px;
  height: 56px !important;
  margin: 0 auto;
}

.searchButton {
  background-color: #263489;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  width: 216px;
  height: 56px !important;
  margin: 0 auto;
}

.requestButton:hover {
  background-color: #78b7e3 !important;
}

.searchButton:hover {
  background-color: #263489 !important;
}

.requestButton img {
  height: 24px;
  width: 24px;
}

.benefits > span {
  margin-bottom: 40px !important;
}

.benefitsTitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  /* identical to box height */

  color: #1a2560;
  margin-bottom: 40px;
}

.benefits {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 25px;
}

.benefitsExtra {
  max-width: 1050px;
  margin: 40px auto 0 auto;
  padding: 0;
}

.benefitsWrapper {
  background: rgba(38, 52, 137, 0.02);
  border-radius: 8px;
}

.benefit {
  border-top: 1px solid rgba(38, 52, 137, 0.06);
  padding: 20px 0;
}

.benefitLink {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;

  color: #7bb6e1;
}

.benefitLink:hover {
  text-decoration: underline;
}

.benefitTitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;

  color: #1a2560;
}

.serviceIcon {
  float: right;
  margin-left: 2rem;
}

.benefitCol {
  display: flex;
  align-items: center;
  height: 100%;
}

.announcementsContainer {
  background: rgba(38, 52, 137, 0.04);
  padding: 64px 0 0 0;
}

.announcements {
  max-width: 1320px;
  margin: 0 auto;
}

.announcements a {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;

  color: #263489;
}

.mainTitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */

  color: #1a2560;
}

.faqContainer {
  padding: 120px 0;
  border-radius: 0;
}

.faq {
  border: none;
  border-radius: 0;
}

.faq div {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  border: none !important;
}

.faq div > div {
  border: none !important;
}

.faq > div > div:nth-child(2) {
  box-shadow: none;
}

.faq > div > div:nth-child(2) > span {
  max-width: 76%;
}

.faq > div > div:nth-child(1) {
  align-items: center !important;
  border-radius: 0 !important;
  margin-top: 16px;
}

.faq > div > div:nth-child(1) > div:nth-child(1) {
  box-shadow: none;
}

.faq > div > div:nth-child(1) > div:nth-child(1) > span {
  border: 1px solid rgba(38, 52, 137, 0.8);
  border-radius: 25px;
  transform: rotate(90deg);
  padding: 12px !important;
  box-shadow: none;
}

.faq > div > div:nth-child(2) > div {
  border: none !important;
  padding: 0 !important;
  box-shadow: none !important;
}

.faq > div > div:nth-child(2) > div > div {
  box-shadow: none !important;
}

.faq div > div > div > div {
  border: none !important;
  padding: 32px;
}

.faq > div > div:nth-child(1) > span {
  padding: 32px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */
  max-width: 100%;

  color: #1a2560;
}

.messageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(123, 182, 225, 0.08);
  border-radius: 300px;
  height: 242px;
  width: 242px;
  margin: 0 auto 40px auto;
}

.message {
  max-width: 437px;
  margin: 0 auto;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  /* or 180% */

  text-align: center;

  color: #1a2560;
}

.message h2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  /* identical to box height */

  color: #1a2560;
}

.message h4 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #1a2560;
  text-align: left;
  padding: 0 17px;
  margin: 0 0 32px 0;
}

.overlay_header {
  color: #eee;
  text-align: right;
  padding: 0 1rem 0 1rem;
}

.cross {
  font-size: 2rem !important;
  background-color: transparent;
  color: #122b52;
  border: none;
  cursor: pointer;
}

.formItem > div {
  display: flex;
  flex-direction: column;
  /* padding: 8px 0; */
}

.formItem > div > div {
  text-align: start !important;
  flex: auto !important;
  margin-top: 16px;
}

/* 
.formItem>div>div>div {
    width: 714px;
} */

.formItem label {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */

  color: #1a2560;
}

.formItem label:after {
  display: none;
}

.input {
  border: 1px solid rgba(38, 52, 137, 0.3) !important;
  border-radius: 3px !important;
  height: 60px;
  padding: 12px;
  line-height: 1 !important;
}

.formItem textarea,
.inputTextArea {
  border: 1px solid rgba(38, 52, 137, 0.3) !important;
  border-radius: 3px !important;
  padding: 12px;
  line-height: 1.57;
}

.submit {
  background: #7bb6e1;
  border-radius: 67.5px;
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  padding: 17px 63px;
  height: 100%;
  float: right;
  margin-top: 40px;
  margin-bottom: 20px;
}

.submit:hover {
  background-color: #263489 !important;
}

.notificationContainer {
  background: rgba(51, 196, 129, 0.08);
  border-radius: 2px;
  margin-top: 32px;
  padding: 24px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
}

.dangerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(237, 83, 80, 0.08);
  border-radius: 300px;
  height: 242px;
  width: 242px;
  margin: 0 auto 40px auto;
}

.closeOutlinedContainer {
  border: 1px solid #ed5350;
  border-radius: 100%;
  height: 48px !important;
  width: 48px !important;
  display: flex;
  justify-content: center;
  align-items: center;

  background: #fef1f1;
}
.checkOutlinedContainer {
  border: 1px solid #33c481;
  border-radius: 100%;
  height: 48px !important;
  width: 48px !important;
  display: flex;
  justify-content: center;
  align-items: center;

  background: #fff;
}

.details {
  padding: 0 19px;
}

.detailsTitle {
  text-align: left;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #1a2560;
  margin-bottom: 16px !important;
}

.detailsContent {
  margin-bottom: 16px !important;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #1a2560;
  text-align: left;
}

.announcementsBtn {
  text-decoration: underline;
}

@media only screen and (min-width: 576px) and (max-width: 1430px) {
  .container,
  .imageWelcomeTitle {
    /* .imageWelcomeDesc, */
    /* .imageWelcomeDesc2  */

    max-width: 90%;
  }

  .imageWelcomeDesc,
  .imageWelcomeDesc2 {
    max-width: 89%;
  }
}

@media only screen and (max-width: 975px) {
  .request {
    margin-top: 0;
  }

  .imageWelcomeTitle {
    font-size: 39px;
  }

  .imageWelcomeDesc {
    font-size: 25px;
  }

  .imageWelcomeDesc2 {
    font-size: 17px;
  }
}

@media only screen and (max-width: 770px) {
  .imageContainerWelcome {
    margin-bottom: 255px !important;
  }

  .imageWelcome {
    max-height: 219px;
  }

  .imageWelcomeTitle {
    font-style: normal;
    color: #1a2560;
    max-width: 74%;
    padding-top: 251px !important;
    margin-bottom: 32px !important;

    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
  }

  .imageWelcomeDesc {
    max-width: 81%;
    color: #1a2560;
    text-align: center;
    font-size: 18px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .imageWelcomeDesc2 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 44px;
    color: #ffffff;
    max-width: 74%;
    padding-left: 60px;
    display: none;
  }

  .subTitleImage {
    bottom: -20px;
  }

  .TitlePosts,
  .PostCol {
    border-right-color: transparent !important;
  }

  .dashed {
    border-right: none !important;
  }

  .serviceIcon {
    margin-top: 20px;
    float: none;
  }

  .benefitsContainer {
    padding: 7%;
  }

  .requests {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
  }

  .benefit {
    padding-left: 0;
  }

  .announcementsContainer {
    padding: 64px 5% 0 5%;
  }

  .faqContainer {
    padding: 33px 6%;
    border-radius: 0;
  }

  .request {
    margin-top: 0rem;
  }

  .benefits,
  .benefitsExtra {
    display: none;
  }

  .benefitsTitle {
    font-size: 18px;
    line-height: 44px;
    /* identical to box height */

    color: #1a2560;
    margin-bottom: 40px;
  }

  @media only screen and (max-width: 575px) {
    .imageWelcomeDesc {
      max-width: 89%;
    }

    .mainTitle {
      color: #1a2560;
      font-family: "Open Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .announcementsBtn {
      font-family: "Open Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .requestTitle {
      font-size: 18px;
    }

    .requestDesc {
      font-size: 16px;
    }
  }
}
