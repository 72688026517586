.container {
  padding-bottom: 10px;
  width: 100%;
  height: auto;
  background-color: #ffffff;
}

.container > div > div > div {
  max-width: 100%;
}

.title {
  color: var(--blue);
  font-size: 44px;
  text-align: left;
  font-weight: 700;
  padding: 40px 0px 20px 0px;
  line-height: 1.2;
}

.subTitle {
  color: var(--blue);
  font-size: 22px;
  text-align: left;
  line-height: 1.3;
  padding: 20px 20px 10px 180px;
  white-space: break-spaces;
}

.subTitleText {
  color: var(--blue);
  font-size: 22px;
  text-align: right;
  position: absolute;
  bottom: 70px;
  right: 50px;
}

.subTitleImage {
  text-align: right;
  position: absolute;
  bottom: 0;
}

.hr {
  display: inline-block;
  width: 150px;
  margin-left: 0;
  height: 5px;
  border-width: 0;
  background-color: var(--blue);
  margin: 0px 30px 12px 0px;
}

.image {
  width: 110%;
  height: auto;
}

p {
  white-space: break-spaces;
}

.info {
  max-width: 1186px;
  background-color: rgba(42, 87, 110, 0.2);
  margin: 0 auto;
}

.TitlePosts {
  font-size: 16px;
  color: #2a576e;
  text-align: center !important;
  margin: 0 auto !important;
  background-color: #d4dce0;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .TitlePosts:nth-child(1) {
    border-right: 1px dashed #2a576e;
}
.TitlePosts:nth-child(2) {
    border-right: none !important;
} */

.titleSection {
  font-size: 16px;
  color: #fff;
  text-align: center !important;
  margin: 0 auto;
  padding: 13px 513px 14px 550px;
  background-color: #2a576e;
}

.textBold {
  padding: 78px 78px 0 78px;
  font-size: 29px;
  font-weight: bold;
  color: #2a576e;
}

.text {
  padding: 44px 78px 44px 79px;
  font-size: 16px;
}

.Post {
  padding: 47px 47px;
  max-width: 489px;
  margin: 0 auto;
  /* min-height: 473px; */
}

.ImagePost {
  /* max-height: 324px; */
  max-height: 269px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  max-width: 100%;
}

.Date {
  font-size: 16px;
  color: #696e71;
  margin: 10px auto;
}

.TitlePost {
  font-size: 20px;
  font-weight: 500;
  color: #2a576e;
}

.ExcerptPost {
  font-size: 16px;
  color: #0e0f0f;
  margin-top: 10px;
  margin-right: 6px;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.PostBtn {
  height: 46px;
  flex-grow: 0;
  border-radius: 14px !important;
  background-color: #2a576e;
  margin-top: 20px;
  letter-spacing: 1.82px;
  color: #fff;
  font-size: 11px !important;
  padding: 0 19px !important;
}

.PostBtn span {
  font-family: "Book", Arial, serif !important;
  font-size: 11px;
  letter-spacing: 1.82px;
  color: #fff;
}

.PostBtn:hover,
.PostBtn:focus {
  /* box-shadow: 0 0.1em 0.5em -0.4em white; */
  /* transform: translateY(-0.1em); */
  border-bottom: 1px solid transparent;
  /* transform: translateX(0.2em); */
  /* border-color: #fff !important; */

  -webkit-transform: scale(0.95) !important;
  -moz-transform: scale(0.95) !important;
  -ms-transform: scale(0.95) !important;
  -o-transform: scale(0.95) !important;
  transform: scale(0.95) !important;
  transition: 0.4s;
}

.pagination {
  margin-top: 55px;
  display: flex !important;
  justify-content: center !important;
}

.pagination li,
.ant-pagination-item-active:active {
  min-height: 47px;
  min-width: 47px !important;
  height: 36px;
  /* background: transparent !important; */
  display: grid !important;
  align-items: center;
  border-color: #bcbcbc !important;
}

.pagination li:first-child,
.pagination li:last-child {
  display: none !important;
}

.pagination a {
  color: #bcbcbc !important;
}

.pagination .ant-pagination-item-active {
  background-color: #2a576e !important;
}

li > div {
  max-width: 100%;
}

.dashed {
  border-right: 1px dashed #2a576e;
}

.container {
  width: 1320px;
  /* margin: 0 auto; */
  max-width: 90%;
  /* display: grid; */
  /* gap: 0;
    align-items: center;
    justify-items: flex-end;
    grid-template-columns: 100% 00%;
    width: 1320px; */
  margin: 0 auto;
}

.imageWelcome {
  height: 596px;
  background: linear-gradient(
      105.32deg,
      rgba(38, 52, 137, 0.45) 36.89%,
      rgba(38, 52, 137, 0) 80.06%
    ),
    url("../../../public/img/Image_WelcomePage.png");
  z-index: 3;
}

.imageWelcomeTitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 68px;
  color: #ffffff;
  max-width: 929px;
  padding-top: 200px;
  padding-left: 80px;
  margin-bottom: 40px;
}

.imageWelcomeDesc {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 44px;
  color: #ffffff;
  max-width: 929px;
  padding-left: 80px;
}

.benefitsContainer {
  padding: 80px 0;
}

.requests {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 120px;
}

.request {
  border: 1px solid rgba(123, 182, 225, 0.6);
  border-radius: 30px;
  padding: 42px 36px;
  display: flex;
  justify-content: center;
  /* width: 100%; */
  flex-direction: column;
  max-width: 440px;
}

.requestTitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-align: center;

  color: #1a2560;
  max-width: 319px;
  margin: 0 auto 32px auto;
}

.requestDesc {
  margin-bottom: 42px;
  max-width: 369px;
  margin: 0 auto 42px auto;
  text-align: center;
}

.requestButton {
  background-color: #263489;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */

  color: #ffffff;
}

.requestButton img {
  height: 20px;
  width: 16px;
}

.benefits > span {
  margin-bottom: 40px !important;
}

.benefitsTitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  /* identical to box height */

  color: #1a2560;
  margin-bottom: 40px;
}

.benefits {
  /* max-width: 1160px; */
  margin: 0 auto;
  /* padding: 56px 25px; */
}

.benefitsExtra {
  max-width: 1050px;
  margin: 0 auto;
  padding: 0;
}

.benefitsWrapper {
  background: rgba(38, 52, 137, 0.02);
  border-radius: 8px;
}

.benefit {
  border-top: 1px solid rgba(38, 52, 137, 0.06);
  padding: 36px 0 36px 0;
}

.Title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #1a2560;
  margin-bottom: 24px;
}

.Date {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #7bb6e1;
  margin-bottom: 16px;
}

.Desc {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #1a2560;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.imgNews {
  max-width: 324px;
}

.serviceIcon {
  float: right;
  margin-left: 2rem;
}

.benefitCol {
  display: flex;
  align-items: center;
  height: 100%;
}

.announcementsContainer {
  background: rgba(38, 52, 137, 0.04);
  padding: 64px 0;
}

.announcements {
  max-width: 1320px;
  margin: 0 auto;
}

.announcements span,
.announcements a {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;

  color: #263489;
}

.faqContainer {
  padding: 120px 0;
  border-radius: 0;
}

.faq {
  border: none;
  border-radius: 0;
}

.faq div {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  border: none !important;
}

.faq div > div {
  border: none !important;
}

.faq > div > div:nth-child(2) {
  box-shadow: none;
}

.faq > div > div:nth-child(1) {
  align-items: center !important;
}

.faq > div > div:nth-child(1) > div:nth-child(1) {
  box-shadow: none;
}

.faq > div > div:nth-child(1) > div:nth-child(1) > span {
  border: 1px solid rgba(38, 52, 137, 0.8) !important;
  border-radius: 25px;
  transform: rotate(90deg);
  padding: 12px !important;
  box-shadow: none;
}

.faq > div > div:nth-child(2) > div {
  border: none !important;
  padding: 0 !important;
  box-shadow: none !important;
}

.faq > div > div:nth-child(2) > div > div {
  box-shadow: none !important;
}

.faq div > div > div > div {
  border: none !important;
  padding: 32px;
}

.faq > div > div:nth-child(1) > span {
  padding: 32px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */

  color: #1a2560;
}

@media only screen and (max-width: 575px) {
  .container {
    padding-top: 24px;
  }

  .benefitsContainer {
    padding: 0;
  }

  .title {
    font-size: 30px;
    padding: 0px;
  }

  .Title {
    font-size: 18px;
  }
  .subTitle {
    font-size: 20px;
    font-weight: 500;
    padding: 20px 0px;
  }

  .hr {
    width: 0px;
    margin: 0px;
  }

  .subTitleText {
    margin-top: 20px;
    bottom: -20px;
    right: 210px;
  }

  .subTitleImage {
    bottom: -20px;
  }

  .TitlePosts,
  .PostCol {
    border-right-color: transparent !important;
  }

  .dashed {
    border-right: none !important;
  }

  .serviceIcon {
    margin-top: 20px;
    float: none;
  }

  .imgNews {
    max-width: 100%;
  }

  .benefitsTitle {
    font-size: 18px;
  }

  .Desc {
    font-size: 16px;
  }
}
