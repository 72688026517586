.modal_overlay {
  z-index: 9999;
  width: 100%;
  height: 219vh;
  position: fixed;
  top: 0;
  background: rgba(38, 52, 137, 0.24);
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding-top: 12rem;
}

.modal_box {
  display: block;
  background: white;
  width: 55%;
  max-width: 624px;
  /* height: 24%; */
  padding: 1rem;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
  position: fixed;
}

@media screen and (max-width: 600px) {
  .modal_box {
    width: 75%;
  }
}